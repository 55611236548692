import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";

import withStyles from "@material-ui/core/styles/withStyles";
import transactionsPageStyle from "../../../assets/jss/user/transactionsPageStyle.jsx";
const history = createBrowserHistory();

const BillingHistory = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
        };
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadTransactions();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    loadTransactions(viewMore = false){
        const source = axios.CancelToken.source();
        let { page } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200
        };
        history.push("/user/billinghistory?page="+page);
        Api.getTransactions(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
        });
    }
    getTableData(){
        const { response } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        const plans = Api.getPlans();
        let plansArray = {};
        plans.map(plan => {
            plansArray[plan.id] = plan.label;
            return null;
        });
        response.map(item => {
            item['plan'] = "";
            if(plansArray.hasOwnProperty(item.plan_id)){
                item['plan'] = plansArray[item.plan_id];
            }
            tableData.push(item);
            return null;
        });
        return tableData;
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem>
                                <h3 className={classes.title}>Billing History</h3>
                            </GridItem>
                        </div>
                        <div className={classes.files}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                    <ReactTable
                                        columns={[
                                            {
                                                Header: "ID",
                                                accessor: "id",
                                                headerClassName: "hd_id",
                                                className: "hd_id td_id",
                                            },
                                            {
                                                Header: "Plan",
                                                accessor: "plan",
                                                headerClassName: "hd_plan",
                                                className: "hd_plan td_plan",
                                            },
                                            {
                                                Header: "ChargeBee Transaction ID",
                                                accessor: "gateway_transaction_id",
                                                headerClassName: "hd_gateway_transaction",
                                                className: "hd_gateway_transaction td_gateway_transaction",
                                            },
                                            {
                                                Header: "Amount",
                                                accessor: "amount",
                                                headerClassName: "hd_amount",
                                                className: "hd_amount td_amount",
                                            },
                                            {
                                                Header: "Currency",
                                                accessor: "currency",
                                                headerClassName: "hd_currency",
                                                className: "hd_currency td_currency",
                                            },
                                            {
                                                Header: "Payment Method",
                                                accessor: "payment_method",
                                                headerClassName: "hd_payment_method",
                                                className: "hd_payment_method td_payment_method",
                                            },
                                            {
                                                Header: "Card",
                                                accessor: "masked_card_number",
                                                headerClassName: "hd_card_number",
                                                className: "hd_card_number td_card_number",
                                            },
                                        ]}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.filesTable}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                            }
                            
                        </div>
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadTransactions(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

BillingHistory.propTypes = {
    classes: PropTypes.object
};

export default withStyles(transactionsPageStyle)(BillingHistory);
