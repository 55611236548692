import { sidebar } from "./material-kit-pro-react";

const appStyle = theme => ({
    mainApp: {

    },
    mainAppResponsiv: {
        paddingTop: "0px",
        display: "flex",
        "& header": {
            boxShadow: "none",
            backgroundColor: "transparent"
        },
        "&.layout_page_help_about, &.layout_page_plans, &.layout_page_help, &.layout_page_privacy, &.layout_page_legal_terms, &.layout_page_contact": {
            "& .bg_slider": {
                display: "none !important"
            }
        },
    },
    backgroundOverlay: {
        "&:before": {
            content: '""',
            background: "linear-gradient(180deg, #062240 0%, #1D212E 100%)",
            width: "100%",
            height: "100%",
            position: "fixed",
            left: "0px",
            top: "0px",
            zIndex: "1"
        },
    },
    staticPage: {
        "& header": {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        }
    },
    bgSlider: {
        position: "fixed",
        left: "0px",
        zIndex: 0
    },
    sidebar: {
        ...sidebar(theme)
    },
    mainContent: {
        display: "flex",
        alignItems: "flex-start",
        "& .leftbar_content": {
            position: "relative",
            zIndex: "5"
        },
        "& .rigth_content": {
            flex: "1",
        },
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    }
});

export default appStyle;