import {
    whiteColor,
    grayColor,
    dangerColor,
    blackColor,
    hexToRgb,
    controls,
    controlButtons,
    sidebar,
    tableHeadColumns,
    separator,
    infoColor,
    content,
    editor
} from "../material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const userEditPageStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    controls: {
        ...controls(theme),
    },
    controlButtons: {
        ...controlButtons(theme),
    },
    sidebar: {
        ...sidebar(theme)
    },
    modalRoot: {
        overflow: "auto",
        display: "block"
    },
    modal: {
        [theme.breakpoints.up("sm")]: {
            maxWidth: "500px",
            margin: "auto"
        },
        borderRadius: "6px",
        overflow: "visible",
        maxHeight: "unset",
        width: "60%",
        marginTop: "10px !important",
        boxShadow: "0px 11px 15px -7px rgba(255, 255, 255, 0.2), "+
                "0px 24px 38px 3px rgba(255, 255, 255, 0.14), "+
                "0px 9px 46px 8px rgba(255, 255, 255, 0.12)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "50px !important",
            margin: theme.spacing(3)
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "10px !important",
            margin: theme.spacing(2),
            width: "400px",
            maxWidth: "100%"
        }
    },
    modalHeader: {
        borderBottom: "none",
        paddingRight: "0px",
        paddingBottom: "0px",
        paddingLeft: "0px",
        minHeight: "0px"
    },
    modalTitle: {
        margin: "0",
        lineHeight: "1.5",
        textAlign: "center",
    },
    modalCloseButton: {
        "&, &:hover": {
            color: grayColor[0]
        },
        "&:hover": {
            opacity: "1"
        },
        cursor: "pointer",
        padding: "1rem",
        margin: "-1rem -1rem -1rem auto",
        backgroundColor: "transparent",
        border: "0",
        WebkitAppearance: "none",
        float: "right",
        fontSize: "1.5rem",
        fontWeight: "500",
        lineHeight: "1",
        textShadow: "0 1px 0 " + whiteColor,
        opacity: ".5",
    },
    modalClose: {
        width: "16px",
        height: "16px"
    },
    modalBody: {
        paddingTop: "24px",
        paddingRight: "24px",
        paddingBottom: "16px",
        paddingLeft: "24px",
        position: "relative",
        overflow: "visible",
        textAlign: "center"
    },
    modalFooter: {
        padding: "15px",
        paddingTop: "0",
        margin: "0 auto"
    },
    modalFooterCenter: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    modalCloseButtonCustom: {
        position: "absolute",
        top: "0px",
        right: "0px",
        margin: "0px",
        "& svg": {
            marginRight: "0px"
        }
    },
    addbutton:
    {padding: "12px 30px",
    margin: "1.3125rem 1px",
    fontSize: "12px"},

    textCenter: {
        textAlign: "center"
    },
    justifyContentCenter: {
        WebkitBoxPack: "center !important",
        MsFlexPack: "center !important",
        justifyContent: "center !important",
        alignSelf: "center !important",
        marginTop: "0px"
    },

    main: {
        marginTop: "0px",
        "& .react-contextmenu": {
            zIndex: "3",
            backgroundColor: whiteColor,
            cursor: "pointer",
            width: "275px",
            textAlign: "left",
            boxShadow:
                "0 4px 20px 0 rgba(" +
                hexToRgb(blackColor) +
                ",.14), 0 7px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ",.4)",
            "& .react-contextmenu-item": {
                padding: "5px 10px",
                fontSize: "14px",
                "&:hover": {
                    backgroundColor: grayColor[2]
                },
                "& $fileIcon": {
                    padding: "0px"
                }
            },
            "& .react-contextmenu-item + .react-contextmenu-item": {
                borderTop: "1px solid "+grayColor[6]
            }
        }
    },
    container: {
        width: "100%",
        zIndex: "2",
        top: "62px",
        position: "relative",
        paddingTop: "20px",
        height: "calc(100vh - 62px)"
    },
    downloadsMainContainer: {
        height: "100%"
    },
    content: {
        ...content(theme)
    },
    separator: {
        ...separator(theme)
    },
    filesTable: {
        ...tableHeadColumns(theme),
        "& .rt-table": {
            "&:not(:hover)": {
                "&::-webkit-scrollbar": {
                    width: "15px",
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "rgba(0,0,0,0.3)",
                },
                "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0,0,0,0.1)",
                    
                }
            }
        },
        "& .rt-tbody .rt-td, & .rt-tbody .rt-td a": {
            fontSize: "12px",
            color: blackColor,
        },
        "& .rt-tbody .rt-td": {
            padding: "0px 5px",
            margin: "5px 0px",
            "&.td_name": {
                margin: "0px",
                padding: "0px",
                "& .react-contextmenu-wrapper": {
                    padding: "0px 5px",
                    margin: "0px",
                    "&>div": {
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        display: "block",
                        width: "100%",
                    }
                }
            },
            // "&.td_hide": {
            //     display: "none"
            // }
        },
        "& .hd_check": {
            width: "30px !important",
            textAlign: "center !important",
            flex: "none !important",
            marginRight: "10px !important",
            "& .MuiCheckbox-root": {
                paddingLeft: "0px",
                paddingRight: "0px"
            },
            "& .rt-resizer": {
                display: "none !important"
            },
        },
        "& .hd_name": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_size": {
            // width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_creation_date": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_related_links": {
            // width: "15% !important",
            textAlign: "left !important",
            "& svg": {
                color: theme.customize.tableRowActionsIconsColor,
                fill: theme.customize.tableRowActionsIconsColor
            }
        },
        "& .td_recipients": {
            width: "15% !important",
            textAlign: "left !important",
            overflow: "visible",
        },
        "& .hd_owner": {
            width: "10% !important",
            textAlign: "left !important",
        },
        "& .hd_activity": {
            width: "20% !important",
            textAlign: "left !important",
            "& span": {
                marginRight: "10px",
            },
            "& .MuiButton-text": {
                textTransform: "capitalize",
                padding: "0px",
                margin: "0px",
                marginBottom: "1px",
                fontWeight: "bold",
            }
        },
        "& .td_check": {
            "& .MuiButtonBase-root": {
                padding: "0px",
            }            
        },
        "& .rt-thead .rt-th.-cursor-pointer > div:first-of-type:after": {
            color:blackColor,
            opacity: "1 !important"
        },
        "& .rt-th.hd_name.rt-resizable-header.-cursor-pointer": {
            color:blackColor,
        },
        "& .rt-td": {
            fontSize: "12px"
        },
        "& .rt-tr": {
            cursor: "pointer",
            alignItems: "center"
        },
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "100px",
            "& .rt-tr-group": {
                flexGrow: "0",
            }
        },
        [theme.breakpoints.down("sm")]: {
            padding: "10px"
        },
        "&.usersTable": {
            "& .left": {
                textAlign: "left"
            }
        },
        "&.loginVideosTable": {
            "& .left": {
                textAlign: "left !important"
            },
            "& .hd_type, & .hd_actions": {
                width: "50px !important",
                flex: "50 0 auto !important",
            },
            "& .hd_title": {
                width: "160px !important",
                flex: "160 0 auto !important",
            },
            "& .hd_actions": {
                "& button": {
                    "&:first-child": {
                        paddingLeft: "0px",
                    },
                    "&:last-child": {
                        paddingRight: "0px"
                    }
                }
            }
        },
        "& .hd_sender": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_path": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_recipients": {
            width: "15% !important",
            textAlign: "left !important",
        },
        "& .hd_expire": {
            width: "12% !important",
            textAlign: "left !important",
        },
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: theme.customize.checkboxColor,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor
    },
    fileIcon: {
        height: "auto",
        width: "auto",
        padding: "0px 3px",
        minWidth: "0px",
        margin: "0px",
        marginRight: "5px",
        "& img": {
            width: "24px"
        },
        "& .questionmark_icon": {
            color: theme.customize.tableRowActionsIconsColor,
            width: "24px",
            height: "24px"
        },
        color: theme.customize.color,
        "& .star_filled": {
            color: theme.customize.sidebarMenuIconsColor
        },
        "& .privacy-icon": {
            marginLeft: "5px"
        }
    },
    loadMore: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    title: {
        color: theme.customize.pageTitleColor,
        marginBottom: "20px",
        width: "100%",
        textShadow: theme.customize.textShadow,
        marginTop: "0px",
        textAlign: "left",
    },
    videoTitle: {
        color: theme.customize.color,
        textShadow: "2px 2px 2px #000",
        fontSize: "16px"
    },
    videoContainer: {
        textAlign: "left",
        "& iframe": {
            [theme.breakpoints.down("md")]: {
                maxWidth: "100%",
            },
            [theme.breakpoints.down("sm")]: {
                maxHeight: "300px",
            },
            [theme.breakpoints.down("xs")]: {
                maxHeight: "250px",
            }
        },
        
    },
    supportContainer: {
        textAlign: "left",
        "& $formFooter":{
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
            "& button": {
                color: theme.customize.color,
            }
        }
    },
    supportResources: {
        textAlign: "left",
        "& .section-title": {
            margin: "0 0 2rem",
            fontWeight: "500",
            color: theme.customize.color,
            "&::after": {
                content: '""',
                margin: ".75rem 0 0",
                border: "none",
                borderRadius: ".125rem",
                backgroundColor: theme.customize.color,
                display: "block",
                height: ".25rem",
            }
        },
        "& .section-content": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            borderRadius: "5px",
        },
        "& .resources-section-content": {
            "& .resources-content": {
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(3),
                "& svg": {
                    marginRight: "10px",
                    width: "1.6em",
                    height: "1.6em",
                    fill: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontWeight: "500",
                    "&:hover": {
                        textDecoration: "none"
                    }
                },
                "& p":{
                    marginBottom: "0px",
                    color: theme.customize.color
                }
            }
        },
    },
    formFields: {
        "& > div": {
            marginBottom: "10px",
            overflow: "hidden",
            clear: "both",
            "& .MuiInput-multiline": {
                "&:before, &:after": {
                    content: "none"
                }
            }
        },
        "& .formElement": {
            width: "100%",
            padding: "0",
            position: "relative",
            "& p":{
                color:blackColor,
                textAlign: "left",
                fontSize: "14px",
                margin: "0px",
                textShadow: "2px 2px 2px #000",
            }
        },
        "& input, textarea": {
            backgroundColor: whiteColor,
            padding: "10px",
            boxSizing: "border-box",
            borderRadius: "3px",
            width: "100%",
            verticalAlign: "top",
            display: "inline-block",
            border: theme.customize.border,
            float: "right",
        },
        "& textarea": {
            minHeight: "200px"
        },
        "& label": {
            color: blackColor,
            maxWidth: "150px",
            width: "100%",
            textAlign: "right",
            fontSize: "18px",
            marginTop: "7px",
            display: "inline-block",
            textShadow: "2px 2px 2px #000",
        },
        "& .claer-icon": {
            position: "absolute",
            top: "5px",
            right: "15px",
            fill: dangerColor[0]
        },
        [theme.breakpoints.down("sm")]: {
            "& label":{
                maxWidth: "100%",
                textAlign: "left",
                display: "block",
                padding: "0",
                fontSize: "16px",
                marginTop: "0px"
            },
            "& textarea": {
                minHeight: "135px",
            },
            "& .formElement": {
                width: "100%",
                float: "none"
            }
        },
    },
    formFooter: {
        position: "relative",
        padding: "0",
        borderRadius: "5px",
        textAlign: "left",
        width: "100% !Important",
        border: "none",
        height: "auto",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        "& img": {
            width: "1.5em",
            marginTop: "-5px",
            marginRight: "0.2em",
        },
        "& Button": {
            "& svg":{
                cursor: "pointer",
                height: "1.5em",
                marginRight: "0.2em",
                width: "1.5em",
                fill: theme.customize.iconsColor,
                fontSize: "inherit",
                transform: "rotate(-45deg)",
                display: "inline-block",
                verticalAlign: 'top',
                marginTop: "-2px",
                "&:hover": {
                    fill: theme.customize.iconsColor,
                },
                "&.sendicon": {
                    transform: "none",
                }
            },
            display: "block",
            fontSize: "1em",
            color: blackColor,
            textTransform: "capitalize",
            textAlign: "left",
            backgroundColor: "transparent !Important",
            width: "100%",
            borderRadius: "0px",
            padding: "10px 8px",
            boxShadow: "none !Important",
            margin: "0px"
        },
    },
    sdBackNewfolder: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& svg": {
            width: "28px",
            height: "28px",
            color: theme.customize.iconsColor
        }
    },
    breadcrumbs: {
        padding: "5px 30px",
        textAlign: "left",
        maxWidth: "100%",
        overflow: "hidden",
        "& a": {
            color: theme.customize.breadcrumbsColor+" !important",
            verticalAlign: "middle",
        },
        "& div": {
            display: "inline-block",
            verticalAlign: "middle",
        },
        "& span": {
            margin: "0px 5px",
            verticalAlign: "middle",
            display: "inline-block",
            height: "18px",
            "& svg": {
                width: "18px",
                height: "18px"
            }
        },
        "& img": {
            width: "18px",
            marginRight: "5px"
        }
    },
    downloadsContainer: {
        "& >div": {
            "&+div": {
                marginTop: theme.spacing(8),
            },
            "&:last-child": {
                marginBottom: theme.spacing(8)
            }
        }
    },
    imageColumn: {
        "& img": {
            width: "100%",
            maxHeight: "150px",
            objectFit: "cover",
            objectPosition: "center",
            [theme.breakpoints.down("md")]: {
                maxHeight: "180px"
            }
        },
    },
    textColumn: {
        whiteSpace: "break-spaces",
        "& p": {
            color: theme.customize.color
        },
        "& h3": {
            color: theme.customize.color,
            marginTop: "0px",
            lineHeight: "normal"
        }
    },
    buttonColumn: {
        textAlign: "center",
        "& img":{
            maxWidth: "120px",
            width: "100%"
        },
        "& a": {
            cursor: "pointer",
        },
        "& button": {
            maxWidth: "120px",
            width: "100%",
            padding: "0px",
            color: theme.customize.color,
            "&:hover": {
                color: theme.customize.color,
            },
            "& img": {
                maxWidth: "18px",
                width: "100%",
                marginRight: "5px"
            },
            "&.window": {
                backgroundColor: "#20b5c9"
            },
            "&.mac": {
                backgroundColor: "#b98cd1"
            }
        }
    },
    tutorialsMainContainer: {
        paddingBottom: theme.spacing(8),
        height: "100%"
    },
    mainVideoColumn: {
        "& iframe": {
            width: "100%"
        },
        "& p": {
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            marginTop: "10px",
            marginBottom: "3px"
        },
    },
    sideVideosColumn: {
        "& >div+div": {
            marginTop: theme.spacing(1),
        },
        "& img": {
            width: "100%",
            maxHeight: "120px",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
            [theme.breakpoints.down("sm")]: {
                height: "auto"
            }
        },
        "& .video-thumbnail": {
            paddingRight: "8px",
        },
        "& .video-detail": {
            paddingLeft: "0px"
        },
        "& p": {
            margin: "0px",
            lineHeight: "normal",
        },
        "& .video-title": {
            fontWeight: "bold",
            cursor: "pointer",
            marginBottom: "3px"
        },
        [theme.breakpoints.down("md")]: {
            marginTop: theme.spacing(4)
        }
    },
    filesContainer: {
        maxWidth: "99%",
        width: "100%",
        maxHeight: "420px",
        overflow: "auto",
        minHeight: "420px",
    },
    recipients: {
        "& div": {
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        "& ul": {
            display: "none",
            listStyleType: "none",
            paddingLeft: "0px",
            position: "absolute",
            zIndex: "1",
            background: theme.customize.buttonsColor,
            borderRadius: "3px",
            "& li": {
                padding: "3px 5px"
            }
        },
        "&:hover": {
            "& ul": {
                display: "block"
            }
        }
    },
    accountContainer: {
        textAlign: "left",
        "& .section-title": {
            margin: "1rem 0 2rem",
            fontWeight: "500",
            color: theme.customize.color,
            "&::after": {
                content: '""',
                margin: ".75rem 0 0",
                border: "none",
                borderRadius: ".125rem",
                backgroundColor: theme.customize.color,
                display: "block",
                height: ".25rem",
            }
        },
        "& .section-content": {
            backgroundColor: theme.customize.opacityColor,
            border: theme.customize.border,
            borderRadius: "5px",
        },
        "& .profile-section-content": {
            padding: "2.5rem 1rem 1.25rem",
            textAlign: "center",
            "& .user-image": {
                display: "inline-block",
                position: "relative",
                marginBottom: "1.5rem",
                "& .user-icon": {
                    width: "8rem",
                    height: "8rem",
                    display: "inline-block",
                    backgroundColor: grayColor[7],
                    borderRadius: "100%",
                    lineHeight: "8rem",
                    fontSize: "40px",
                    fontWeight: "bold",
                    color: blackColor,
                    "& img.profile-picture": {
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                        verticalAlign: "top",
                        objectFit: "cover"
                    }
                },
            },
            "& .user-name": {
                fontSize: "1.75rem",
                lineHeight: "normal",
                wordBreak: "break-all",
                marginTop: "0px",
                marginBottom: ".75rem",
                color: theme.customize.color
            },
            "& .user-email": {
                marginBottom: "1.75rem",
                color: theme.customize.color,
                wordBreak: "break-all"
            }
        },
        "& .plans-section-content": {
            "&>div":{
                padding: "1.25rem",
            },
            "& .plan-header": {
                minHeight: "5.5rem",
                "& img": {
                    height: "2rem"
                },
                "& h4": {
                    margin: ".5rem 0 0",
                    fontSize: "1.25rem",
                }
            },
            "& .plan-body": {
                minHeight: "7.9rem",
                "& p": {
                    marginBottom: "0px"
                }
            }
        },
        "& .resources-section-content": {
            "& .resources-content": {
                display: "flex",
                alignItems: "center",
                marginBottom: theme.spacing(3),
                "& svg": {
                    marginRight: "10px",
                    width: "1.6em",
                    height: "1.6em",
                    fill: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                    fontSize: "1.125rem",
                    lineHeight: "normal",
                    fontWeight: "500",
                    "&:hover": {
                        textDecoration: "none"
                    }
                },
                "& p":{
                    marginBottom: "0px",
                    color: theme.customize.color
                }
            }
        },
        "& .tasks-section-content": {
            "& ul":{
                listStyleType: "none",
                padding: "0px",
                "& li":{
                    marginBottom: ".5rem",
                    "& a": {
                        color: theme.customize.linksColor,
                        fontSize: "1.125rem",
                        lineHeight: "1.75rem",
                        fontWeight: "500"
                    }
                }
            }
        }
    },
    profileContainer: {
        "& .userInfo": {
            marginTop: theme.spacing(2),
            "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
                color: theme.customize.color+" !important",
                textAlign: "left",
                "&.MuiFormControlLabel-root": {
                    width: "100%",
                    "& a": {
                        color: theme.customize.linksColor
                    }
                },
                "& span": {
                    color: theme.customize.color
                },
                "&[class*='labelRootError']": {
                    color: "#f44336 !important",
                }
            },
            "& .MuiInputBase-root.Mui-disabled": {
                backgroundColor: "transparent"
            },
            "& label.Mui-disabled span": {
                color: grayColor[12]
            }
        },
        "& .saveButtons": {
            display: "flex",
            justifyContent: "space-between",
            "&.saveButtonsBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .editButton": {
            display: "flex",
            justifyContent: "flex-end",
            "&.editButtonBottom": {
                marginBottom: theme.spacing(5)
            }
        },
        "& .passwordCheck-notValid-customizable": {
            color: theme.customize.linksColor,
        },
        "& .form-error-message": {
            marginBottom: "10px",
        }
    },
    accountsContainer: {
        "& .accounts-section": {
            border: "1px solid",
            "& h4": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                padding: "10px",
                marginTop: "0px",
                textAlign: "left",
                fontWeight: "500",
                color: blackColor ,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
            },
        },
        "& .accounts-content": {
            padding: "10px",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                textAlign: "left",
                "& span": {
                    display: "inline-block",
                    fontWeight: "500",
                    width: "30%",
                }
            },
            "& .rt-tbody .rt-td, .rt-thead .rt-th": {
                textAlign: "left"
            },
            "& .td_actions": {
                "& button": {
                    padding: "0px",
                    margin: "0px",
                    textTransform: "capitalize"
                }
            },
            "& .rt-tbody": {
                "& .td_state": {
                    textTransform: "capitalize"
                }
            }
        },
    },
    privacyContainer: {
        "& .privacy-section": {
            textAlign: "left",
            "& h4": {
                color: theme.customize.color
            },
            "& p": {
                color: theme.customize.color
            },
            "& ul": {
                paddingLeft: "30px",
                "& li::marker": {
                    color: theme.customize.color
                },
                "& a": {
                    color: theme.customize.linksColor,
                }
            },
            "& .delete-account": {
                backgroundColor: theme.customize.opacityColor,
                padding: theme.spacing(3),
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& p": {
                    marginBottom: "0px",
                    wordBreak: "break-word",
                }
            }
        }
    },
    exportContainer: {
        "& .export-section": {
            textAlign: "left",
            "& .error": {
                "& svg": {
                    borderColor: dangerColor[0],
                },
                "& .MuiFormControlLabel-label": {
                    //color: dangerColor[0]
                }
            },
            "& p": {
                color: theme.customize.color
            }
        },
        "& input, & label, & label.MuiFormLabel-root:not(.Mui-error), & .MuiSelect-select": {
            color: theme.customize.color+" !important",
            textAlign: "left",
            "&.MuiFormControlLabel-root": {
                width: "100%",
                "& a": {
                    color: theme.customize.linksColor
                }
            },
            "& span": {
                color: theme.customize.color
            }
        },
        "& .MuiInputBase-root.Mui-disabled": {
            backgroundColor: "transparent"
        },
        "& .request-button": {
            padding: theme.spacing(0, 5, 5, 0),
            display: "flex",
            justifyContent: "flex-end",
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(0, 0),
            }
        },
        "& .custom-select": {
            maxWidth: "150px",
            width: "100%",
            marginTop: "0px !Important",
            "& svg": {
                color: theme.customize.color
            }
        },
        "& .size-section": {
            display: "flex",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "30px",
                "& li": {
                    color: theme.customize.color
                }
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                "& ul": {
                    paddingLeft: "0px"
                }
            }
        },
        "& .date-range-section": {
            display: "flex",
            alignItems: "center",
            "& >*": {
                width: "auto !important",
                paddingTop: "0px !important",
                marginBottom: "0px !important",
                marginRight: "30px",
                "&:last-child": {
                    marginRight: "0px"
                }
            },
            [theme.breakpoints.down("xs")]: {
                display: "block",
                marginBottom: "10px",
                "& >*": {
                    width: "100% !Important",
                }
            }
        }
    },
    downloadContainer: {
        "& .download-section": {
            textAlign: "left",
            "& h4, & p, & li": {
                color: theme.customize.color
            }
        },
        "& .download-detail": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            "& ul": {
                listStyleType: "none",
                paddingLeft: "0px",
                "& span": {
                    fontWeight: "bold"
                }
            },
            "& button": {
                padding: "0px",
                margin: "0px",
                color: theme.customize.linksColor,
                textTransform: "none",
                fontSize: "16px",
                "& svg": {
                    marginLeft: "5px",
                    width: "22px",
                    height: "22px",
                }
            }
        },
        "& .download-files": {
            "& ul": {
                paddingLeft: "0px",
                listStyleType: "none",
                marginBottom: "0px",
                "&.download-files-lists>li": {
                    borderTop: "1px solid "+theme.customize.borderColor,
                    paddingTop: "10px",
                    paddingBottom: "10px"
                },
                "& ul": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "& Button": {
                        padding: "0px",
                        margin: "0px",
                        marginLeft: "5px",
                        marginTop: "-3px",
                        color: theme.customize.linksColor,
                        "& svg": {
                            width: "22px",
                            height: "22px"
                        }
                    },
                    "& img": {
                        width: "36px",
                        height: "36px",
                        marginRight: "10px"
                    }
                }
            }
        }
    },
    customTabs: {
        "& .card": {
            backgroundColor: "transparent",
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
        },
        "& .card-header": {
            marginLeft: "0px",
            marginRight: "0px",
            boxShadow: "none",
            background: "rgba(0, 0, 0, 0.5)",
            padding: "10px 0px",
        }
    },
    usersContainer: {
        maxWidth: "99%",
        width: "100%",
        maxHeight: "500px",
        overflow: "auto",
        minHeight: "500px",
        "& .td_actions": {
            "& button": {
                paddingTop: "0px",
                paddingBottom: "0px",
                margin: "0px",
                textTransform: "capitalize"
            }
        },
        "& .hd_actions": {
            textAlign: "center !important"
        }
    },
    popupContent: {
        color: blackColor,
        width: "300px",
        borderRadius: "8px",
        "& .top-content": {
            padding: "20px 30px 0px",  
            [theme.breakpoints.down("md")]: {
                padding: "20px 20px 0px",  
            },
            textAlign: "Center",
            "& .user-manage": {
                border: "1px solid "+ theme.customize.borderColor
            },
            "& .user-image": {
                marginBottom: "0px",
                display: "inline-block",
                position: "relative",
                "& .uploadPhotoInput": {
                    "&  .MuiFormControl-root":{
                        margin: "0px",
                        padding: "0px",
                        "& .MuiInput-formControl":{
                            "&:before": {
                                display: "none"
                            }
                        },
                        "& input": {
                            display: "none"
                        }
                    }
                },
                "& button": {
                    position: "absolute",
                    backgroundColor: whiteColor,
                    bottom: "0px",
                    right: "0px",
                    padding: "5px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                    margin: "0px",
                    "& svg": {
                        width: "16px",
                        height: "16px",
                        color: grayColor[7]
                    }
                },
                "& .user-icon": {
                    width: "80px",
                    height: "80px",
                    lineHeight: "80px",
                    fontSize: "40px",
                    fontWeight: "bold",
                    "& .sd_loader": {
                        height: "80px"
                    },
                }
            },
            "& h4": {
                margin: "0px",
                lineHeight: "normal",
            },
            "& p": {
                marginBottom: "5px",
            },
            "& .stats-section": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "10px",
            },
            "& .left-stats": {
                marginRight: "30px",
                "& .graph-stats": {
                    // width: "70px",
                    height: "70px",
                    //border: "1px solid "+grayColor[11],
                    margin: "0px auto",
                    borderRadius: "100%",
                    "& span": {
                        width: "100%",
                        height: "100%",
                        display: "block",
                        backgroundColor: theme.customize.buttonsColor,
                        borderRadius: "100%",
                        border: "3px solid "+theme.customize.borderColor,
                    },
                    "& .ct-chart": {
                        width: "67px",
                        height: "67px",
                        margin: "0px auto",
                        "& .ct-label": {
                            fill: theme.customize.color
                        },
                        "& .ct-series-b .ct-slice-pie": {
                            fill: grayColor[13]
                        },
                        "& .ct-series-a .ct-slice-pie": {
                            fill: theme.customize.iconsColor
                        }
                    }
                },
                "& .data-detail": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6px",
                    "& span": {
                        fontSize: "12px",
                        lineHeight: "normal",
                        "&.used-data": {
                            color: theme.customize.linksColor,
                            marginRight: "5px",
                            paddingRight: "5px",
                            borderRight: "1px solid "+theme.customize.borderColorModal,
                        },
                    }
                }
            },
            "& .right-stats": {
                maxWidth: "110px",
                width: "100%",
                "& ul":{
                    listStyleType: "none",
                    padding: "0px",
                    margin: "0px",
                    "& ul": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "12px",
                        lineHeight: "normal",
                        marginBottom: "4px",
                        "& .stat-name": {
                            display: "flex",
                            "& span": {
                                width: "12px",
                                height: "12px",
                                display: "inline-block",
                                marginRight: "5px",
                                backgroundColor: grayColor[7],
                                "&.web": {
                                    backgroundColor: "#17965b",
                                },
                                "&.outlook": {
                                    backgroundColor: "#4374dd",
                                },
                                "&.app": {
                                    backgroundColor: "#7f3897",
                                },
                                "&.uploads": {
                                    backgroundColor: "#4071e7",
                                },
                                "&.my_docs": {
                                    backgroundColor: "#ff0303",
                                }
                            }
                        }
                    }
                }
            },
            "& .signup-btn": {
                marginBottom: "-25px",
            },
        },
        "& .bottom-content": { 
            "& ul": {
                display: "flex",
                padding: "0px",
                listStyleType: "none",
                justifyContent: "center",
                borderTop: "1px solid "+grayColor[11],
                paddingTop: "25px",
                paddingBottom: "10px",
                margin: "0px",
                "& >li:first-child": {
                    "&:after": {
                        content: '""',
                        width: "3px",
                        height: "3px",
                        borderRadius: "100%",
                        backgroundColor: blackColor,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginTop: "2px",
                        marginLeft: "10px",
                        marginRight: "10px"
                    }
                },
                "& a": {
                    color: blackColor,
                    fontSize: "12px",
                    "&:hover": {
                        textDecoration: "none"
                    }
                }
            }
        },
    },
    popoverRoot: {
        "& .MuiPaper-root": {
            left: "auto !important",
            right: "40px !important",
            transformOrigin: "unset !important"
        }
    },
    userFirstLetter: {
        "& .user-icon": {
            width: "32px",
            height: "32px",
            backgroundColor: grayColor[7],
            color: blackColor,
            borderRadius: "100%",
            border: "none",
            fontSize: "16px",
            lineHeight: "32px",
            display: "inline-block",
            "& img.profile-picture": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
                verticalAlign: "top"
            },
        }
    },
    rulesContainer: {
        "& .td_emails": {
            "& span": {
                display: "block"
            }
        },
    },
    businessInfoContainer: {
        "& .user-image": {
            marginBottom: "0px",
            display: "inline-block",
            position: "relative",
            "& .uploadPhotoInput": {
                "&  .MuiFormControl-root":{
                    margin: "0px",
                    padding: "0px",
                    "& .MuiInput-formControl":{
                        "&:before": {
                            display: "none"
                        }
                    },
                    "& input": {
                        display: "none"
                    }
                }
            },
            "& button": {
                "& svg": {
                    width: "16px",
                    height: "16px",
                    color: grayColor[7]
                }
            },
            "& .user-icon": {
                width: "190px",
                height: "60px",
                fontSize: "40px",
                fontWeight: "bold",
                borderRadius: "0px",
                display: "block",
                "& .sd_loader": {
                    height: "60px"
                },
                "&.user-picture": {
                    fontSize: "0px",
                    width: "auto",
                    height: "auto",
                    background: "transparent",
                    borderRadius: "0px",
                    lineHeight: "normal",
                    "& img": {
                        borderRadius: "0px",
                        maxHeight: "60px",
                        width: "auto",
                        height: "auto"
                    },
                    "&+.uploadPhotoInput": {
                        marginTop: "30px",
                    }
                },
            },
            "&.background-image": {
                "& .user-icon": {
                    width: "200px",
                    height: "100px",
                    borderRadius: "0px",
                    "& .sd_loader": {
                        height: "100px"
                    },
                    "&.user-picture": {
                        "& img": {
                            maxHeight: "100px",
                        },
                    }
                },
            },
            "& .photos-action": {
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "10px",
            }
        },
        "& .logo-note": {
            marginBottom: "50px",
            color: theme.customize.color
        },
        "& .logo-title": {
            color: theme.customize.color
        }
    },
    editor: {
        ...editor(theme)
    },
    aboutLabel: {
        display: "block",
        textAlign: "left",
        marginBottom: "5px",
        marginTop: "17px",
        color: theme.customize.color
    },
    alignItems: {
        alignItems: "center"
    },
    filterGridItem: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
        "& h4": {
            marginRight: "40px",
        },
        [theme.breakpoints.down("xs")]: {
            "& h4": {
                marginRight: "10px",
            },
        }
    },
    filterGridItemSm: {
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    sharedFilters: {
        listStyleType: "none",
        padding: "0px",
        margin: "10px 0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px"
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.linksColor
                }
            }
        }
    },
    flexItems: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    downloadStyle: {
        textAlign: "left",
        "& $label": {
            color: theme.customize.color
        }
    },
    businessAdsContainer: {
        "& .photo-section-bottom": {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginTop: "-15px",
            "& .note": {
                marginTop: "0px",
                textAlign: "left",
                marginBottom: "0px",
            },
        },
        "& .photo-section": {
            position: "relative",
            "&>img": {
                position: "absolute",
                left: "0px",
                top: "34px",
                width: "40px",
                height: "40px",
                objectFit: "cover",
            },
            "& input": {
                paddingLeft: "45px",
            }
        }
    },
    itemCount: {
        float: "right"
    },
    linkFilters: {
        listStyleType: "none",
        padding: "0px",
        margin: "10px 0px",
        "& li": {
            display: "inline-block",
            cursor: "pointer",
            "&+li": {
                marginLeft: "20px"
            },
            "& img": {
                marginRight: "5px",
                width: "18px"
            },
            "&.active": {
                "& span": {
                    paddingBottom: "2px",
                    borderBottom: "3px solid "+theme.customize.linksColor
                }
            }
        }
    },
    viewType: {
        margin: "10px 0px",
    },
    fileActions: { 
        textAlign: "right",
        padding: "5px 20px",
        marginTop: "10px",
        backgroundColor: theme.customize.opacityColor,
        border: theme.customize.border,
        "& svg": {
            fill: theme.customize.iconsColor,
            color: theme.customize.iconsColor,
            width: "1em",
            display: "inline-block",
            verticalAlign: "middle",
            fontSize: "1.5rem",
            marginRight: "5px"
        },
        "& li + li": {
            "&:before": {
                content: "'|'",
                display: "inline-block",
                marginRight: "20px"
            }
        }
    },
    fileActionEnabled: {
        color: theme.customize.iconsColor
    },
    archivesTable: {
        "& .rt-tbody": {
            overflow: "unset",
            minHeight: "360px",
            "& .rt-tr-group": {
                flexGrow: "0"
            }
        },
    },
    archivesContainer: {
        maxWidth: "99%",
        width: "100%",
        maxHeight: "420px",
        minHeight: "420px",
    },
    stickyRow: {
        "& .rt-tbody": {
            paddingTop: "30px",
            position: "relative",
            "& .rt-tr-group.frozen_row": {
                position: "absolute",
                top: "0px",
                width: "100%",
                left: "0px"
            }
        }
    },
    snackErrorMessage: {
        "& span": {
            color: blackColor
        }
    },
    shareViewContent: {
        width: "95%",
        margin: "0px auto",
        "& $filesTable": {
            "& .hd_check": {
                width: "30px !important",
                textAlign: "left !important",
                flex: "30 0 auto !Important",
                padding: "0px",
            },
            [theme.breakpoints.down("xs")]: {
                padding: "10px 0px"
            },
        },
        "& $breadcrumbs": {
            maxWidth: "100%",
            padding: "5px 0px",
        },
        "& $filesContainer": {
            maxWidth: "100%",
        },
        "& $controls": {
            "& >.MuiGrid-item": {
                paddingLeft: "0px",
                paddingRight: "0px"
            }
        },
        "& $controlButtons": {
            "& button.MuiButton-text": {
                paddingLeft: "0px",
                paddingRight: "0px",
                marginLeft: "0px",
                marginRight: "0px"
            }
        }
    },
    loading: {
        display: "none"
    },
    adminNote: {
        textAlign: "left",
        fontSize: "13px"
    },
    archivesFiles: {
        paddingTop: "20px",
        paddingLeft: "30px",
        maxWidth: "650px",
        "& .files-table": {
            display: "block",
            "& h5": {
                textAlign: "left",
                fontWeight: "500",
                color: theme.customize.headingsColor,
            },
            "& ul": {
                paddingLeft: "10px",
                listStyleType: "none",
            },
            "& .file-list": {
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                marginBottom: "5px",
                "& li": {
                    flex: '25%',
                    textAlign: 'left',
                    fontSize: "14px",
                    "&:first-child": {
                        flex: "50%"
                    },
                    "& button": {
                        padding: "0px",
                        textTransform: "capitalize",
                        fontSize: "14px"
                    }
                }
            }
        }
    },
    filesLayoutType: {
        padding: "5px 30px 10px",
        textAlign: "right",
        maxWidth: "100%",
        "& button": {
            padding: "0px",
            "&:last-child": {
                marginRight: "0px",
            }
        },
        "& svg": {
            width: "28px",
            height: "28px"
        }
    },
    gridTableData: {
        display: "flex",
        flexWrap: "wrap",
        rowGap: "20px",
        columnGap: "20px",
        marginTop: "20px"
    },
    gridDataItem: {
        maxWidth: "200px",
        width: "100%",
        border: "1px solid "+theme.customize.borderColor,
        borderRadius: "6px",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "47%"
        },
        "& .filename": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100%",
            whiteSpace: "nowrap",
            padding: "10px",
            lineHeight: "normal",
            fontSize: "14px",
            backgroundColor: whiteColor,
            borderRadius: "0px 0px 6px 6px",
            color: blackColor,
        },
        "& .checked": {
            "& .filename": {
                backgroundColor: theme.customize.borderColorModal,
                color: blackColor
            }
        },
        "& .thumbnail-icon": { 
            height: "160px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            "& button": {
                margin: "0px",
                padding: "0px",
                cursor: "auto"
            },
            "& svg, & img": {
                width: "60px !important",
                height: "60px !important",
                objectFit: "contain"
            },
            "& img.thumbnail": {
                width: "100% !important",
                objectFit: "cover",
                height: "160px !important",
            }
        }
    },
    externalLinksTable: {
        "& .hd_action": {
            textAlign: "center !important",
            "& button": {
                paddingTop: "0px",
                paddingBottom: "0px",
                height: "auto"   
            }
        },
        "& .hd_url": {
            width: "20% !important",
            textAlign: "left !important",
        },
        "& .hd_password, .hd_title": {
            textAlign: "left !important"
        },
        "& .hd_expire": {
            width: "50px !important"
        },
        "& .hd_password": {
            display: "flex",
            alignItems: "center",
            "& button": {
                height: "auto",
                paddingTop: "0px",
                paddingBottom: "0px",
                minWidth: "0px",
                width: "30px"
            }
        },
        "& .sd-password-field": {
            "& .sd-passwords": {
                display: "flex",
                "& span:before": {
                    content: '""',
                    width: "3px",
                    height: "3px",
                    display: "block",
                    borderRadius: "100%",
                    backgroundColor: theme.customize.color,
                    marginRight: "2px"
                }
            }
        }
    },
    countdown: {
        background: infoColor[0],
        padding: "5px 10px",
        fontSize: "16px",
        borderRadius: "30px",
        marginLeft: "5px"
    },
    customSelect: {
        textAlign: "left"
    },
    errorMessage: {
        color: dangerColor[0]
    }
});

export default userEditPageStyle;
