import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import foldericon from "../../../assets/img/foldericon.png";
import DeleteFileModal from "../../components/User/DeleteFileModal";
import RestoreFileModal from "../../components/User/RestoreFileModal";

import MaterialIcon from "@mdi/react";
import { mdiArrowExpandUp, mdiTrashCanOutline } from '@mdi/js';

import withStyles from "@material-ui/core/styles/withStyles";
import userTrashPageStyle from "../../../assets/jss/user/userTrashPageStyle.jsx";
const history = createBrowserHistory();

const UserTrashFiles = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            restoreFileModal: false,
            trash: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: "",
            checkedAll: false,
            saving: false,
            errorMessage: null,
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadFiles();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, trash } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            trash: 1
        };
        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }
        backPath = this.getBackPath(path);
        history.push("/user/trash/files?dir="+encodeURIComponent(path));
        Api.getDeletedFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.trash){
                    trash.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                trash: trash
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false
        });
        this.loadingFolder = true;
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onToggleAllFiles(e){
        if(this.loadingFolder){
            return;
        }

        const checkedFiles = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedFiles.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedFiles
        });
    }
    getColumns(){
        const { classes } = this.props;

        let header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );

        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },   
        ]

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Button color="transparent" justIcon className={classes.fileIcon}>
                        <img src={foldericon} alt={"folder"} />
                    </Button>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            let fileArray = {
                id: file.id,
                default: file.default,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(file.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(file)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        disabled={(file.default === 1)}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                orignalName: file.name,
                name: this.getFileName(file),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.modified_date,
                owner: file.owner,
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFileName(file){
        const { classes } = this.props;
        return (
            <div>
                {
                    file.type === "folder" ?
                        <>
                            <Button color="transparent" justIcon className={classes.fileIcon}>
                                <img src={foldericon} alt={"folder"} />
                            </Button>
                            {file.name}
                        </>
                    :
                    <>
                        { 
                            helper.getFileIcon(file, classes)
                        }
                        { file.name }
                    </>
                }
            </div>
        )
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadFiles(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    deleteSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({deleteFileModal: true});
    }
    onDeleteModalClose(deleteFileModal = true){
        let checked = this.state.checked;
        if(deleteFileModal === false){
            checked = [];
        }
        this.setState({
            deleteFileModal: deleteFileModal,
            checked: checked,
            checkedAll: false,
        });
    }
    onDeleteModalSuccess(){
        const { checked, response } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            files: checked,
        };
        Api.deleteFilesPermanently(requestData, source).then(data => {
            let newResponse = [];
            response.map(file => {
                if(!checked.includes(file.id)){
                    newResponse.push(file);
                }
                return null;
            });
            this.setState({
                response: newResponse,
                checked: [],
                deleteFileModal: false,
                checkedAll: false,
                saving: false,
                errorMessage: null
            });
        }).catch(err => {
            this.setState({
                saving: false,
                errorMessage: err.message
            });
        });
        this.setState({
            saving: true
        });
    }
    restoreSelected(){
        const { checked } = this.state;

        if(checked.length <= 0){
            return;
        }
        this.setState({restoreFileModal: true});
    }
    onRestoreModalClose(restoreFileModal = true){
        let checked = this.state.checked;
        if(restoreFileModal === false){
            checked = [];
        }
        this.setState({
            restoreFileModal: restoreFileModal,
            checked: checked,
            checkedAll: false,
        });
    }
    onRestoreModalSuccess(){
        const { checked, response } = this.state;
        let newResponse = [];
        response.map(file => {
            if(!checked.includes(file.id)){
                newResponse.push(file);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            restoreFileModal: false,
            checkedAll: false,
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    getCheckedFiles(){
        const { response, checked } = this.state;
        if(checked.length <= 0){
            return [];
        }
        let files = [];
        response.map((file) => {
            if(checked.indexOf(file.id) !== -1){
                files.push(file);
            }
            return null;
        });
        return files;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, deleteFileModal, restoreFileModal, saving, errorMessage } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const checkedFiles = this.getCheckedFiles();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem>
                                <h4>Trash Files</h4>
                            </GridItem>
                            <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                                <Button color="transparent" onClick={() => this.deleteSelected()}>
                                    <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                    Delete Permanently
                                </Button>
                                <span className={classes.separator}></span>
                                <Button color="transparent" onClick={() => this.restoreSelected(true)}>
                                    <MaterialIcon path={mdiArrowExpandUp} className="MuiSvgIcon-root" />
                                    Restore
                                </Button>
                            </div>
                        </div>
                        <div className={classes.files}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                null
                            }
                            <ReactTable
                                columns={this.getColumns()}
                                data={tableData}
                                pageSizeOptions={[500]}
                                defaultPageSize={limit}
                                showPaginationTop={false}
                                minRows={0}
                                showPaginationBottom={false}
                                className={"-striped -highlight "+ (loading ? classes.loading : "")+" "+classes.filesTable}
                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                getNoDataProps={() => this.getNoDataProps()}
                            />
                        </div>
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                {
                    deleteFileModal ?
                        <DeleteFileModal
                            deletedFiles={checkedFiles}
                            open={deleteFileModal}
                            onClose={() => this.onDeleteModalClose(false)}
                            onSuccess={() => this.onDeleteModalSuccess()}
                            loading={saving}
                            errorMessage={errorMessage}
                        />
                    :
                        <></>
                }
                {
                    restoreFileModal ?
                        <RestoreFileModal store={this.store} restoreFiles={checkedFiles} open={restoreFileModal} onClose={() => this.onRestoreModalClose(false)} onSuccess={() => this.onRestoreModalSuccess()} />
                    :
                        <></>
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserTrashFiles.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userTrashPageStyle)(UserTrashFiles);
