import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
// import Link from "@material-ui/core/Link";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const image1 = 'https://api.sendlinx.com/public/slider/bg1.jpg';
const image2 = 'https://api.sendlinx.com/public/slider/bg2.jpg';
const image3 = 'https://api.sendlinx.com/public/slider/bg3.jpg';

const Tutorials = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        let videosArray = this.getVideosArray();
        this.state = {
            videosArray: videosArray,
            currentObject: {
                video: videosArray[0]
            }
        }
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
    }
    getVideosArray(){
        let videosArray = [
            {
                title: "What is Sendlinx",
                description: "What is Sendlinx description",
                code: "vDf5BIlLXAI",
                imgsrc: image1
            },
            {
                title: "ReactJS Tutorial - 1 - Introduction",
                description: "ReactJS Tutorial - 1 - Introduction description",
                code: "QFaFIcGhPoM",
                imgsrc: image2
            },
            {
                title: "ReactJS Tutorial - 2 - Hello World",
                description: "Third video description Third video description Third video description Third video description Third video description Third video description Third video description Third video description Third video description",
                code: "9hb_0TZ_MVI",
                imgsrc: image3
            },
            {
                title: "ReactJS Tutorial - 3 - Folder Structure",
                description: "ReactJS Tutorial - 3 - Folder Structure description",
                code: "9VIiLJL0H4Y",
                imgsrc: image1
            },
            {
                title: "ReactJS Tutorial - 4 - Components",
                description: "ReactJS Tutorial - 4 - Components description",
                code: "Y2hgEGPzTZY",
                imgsrc: image2
            },
        ]
        return videosArray;
    }
    getDescription(description){
        if(!description){
            return null
        }
        if(description.length <= 110){
            return description;
        }
        return description.substr(0,110).trim()+"...";
    }
    playVideo(e, video){
        this.setState({
            currentObject: {
                video: video
            }
        })
        this.renderCurrentVideo()
    }
    renderVideos(video, key){
        return(
            <GridContainer className={"video-listing"} key={key}>
                <GridItem xs={4} sm={3} lg={5} className={"video-thumbnail"}>
                    <img src={video.imgsrc} alt="image1" onClick={(e) => this.playVideo(e, video)} />
                </GridItem>
                <GridItem xs={8} sm={9} lg={7} className={"video-detail"}>
                    <p className={"video-title"} onClick={(e) => this.playVideo(e, video)}>{video.title}</p>
                    <p className={"video-desc"}>{this.getDescription(video.description)}</p>
                </GridItem>
            </GridContainer>
        )
    }
    renderCurrentVideo(){
        const { currentObject } = this.state;
        let video = currentObject.video
        return(
            <div>
                <div className={"main-video"}>
                    <iframe width="100%" height="550px" title="What is Sendlinx?" src={"https://www.youtube.com/embed/"+video.code} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <div className={"video-detail"}>
                    <p className={"video-title"}>{video.title}</p>
                    <p className={"video-desc"}>{video.description}</p>
                </div>
            </div>
        )
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        const { videosArray } = this.state;
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container+" "+classes.tutorialsMainContainer}>
                    <div className={classes.content}>
                        <GridItem>
                            <div className={classes.videoContainer}>
                                <h3 className={classes.title}>Tutorials</h3>
                                <GridContainer>
                                    <GridItem xs={12} lg={8} className={classes.mainVideoColumn}>
                                        {
                                            this.renderCurrentVideo()
                                        }
                                    </GridItem>
                                    <GridItem xs={12} lg={4} className={classes.sideVideosColumn}>
                                        {
                                            videosArray.map((video, key) => {
                                                return this.renderVideos(video, key)
                                            })
                                        }
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

Tutorials.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(Tutorials);
