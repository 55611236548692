import React from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import Clear from "@material-ui/icons/Clear";
import ContactConfirmationModal from "../../components/User/ContactConfirmationModal";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem";
import HelpIcon from "@material-ui/icons/HelpOutline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";
import { Link as RouerLink } from "react-router-dom";
import Link from '@material-ui/core/Link';

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

const UserSupport = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        const { user } = this.store.getState();
        this.state = {
            open: false,
            email: user.email,
            subject: "",
            message: "",
            validation: {
                email: "",
                subject: "",
                message: "",
                isValid: false,
            },
            cancelToken: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onOkClick =this.onOkClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    handleChange(event, name) {
        this.setState({
            ...this.state,
            [name]: event.target.value
        });
    }
    onOkClick(){
        this.resetForm(true);
    }
    handleSubmit(e){
        e.preventDefault();
        const isValid = this.validateForm();
        if(!isValid){
            return;
        }

        const source = axios.CancelToken.source();
        this.setState({cancelToken: source, open: true});
        const requestData = {
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
        };
        
        Api.sendContactRequest(requestData, source).then(data => {
            //Do nothing
            this.resetForm();
        }).catch(err => {
            if(err && err.message !== "Request Cancelled"){
                this.resetForm();
            }  
        });
    }
    validateForm(){
        let validation = {
            email: 'success',
            subject: 'success',
            message: 'success',
            isValid: true
        };

        const values = this.state;
        var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(values.email.length <= 0 || emailRex.test(values.email) === false){
            validation.email = "error";
            validation.isValid = false;
        }
        if(values.subject.length <= 0){
            validation.subject = "error";
            validation.isValid = false;
        }
        if(values.message.length <= 0){
            validation.message = "error";
            validation.isValid = false;
        }
        this.setState({validation: validation});
        return validation.isValid;
    }
    resetForm(hideModal = false){
        let newState = {
            subject: "",
            message: "",
            validation: {
                email: "",
                subject: "",
                message: "",
                isValid: false,
            },
        };
        if(hideModal === true){
            newState['open'] = false;
        }
        this.setState(newState);
    }
    renderResources(){
        return (
            <>
                <div className="resources-section">
                    <h4 className="section-title">Helpful resources</h4>
                    <div className="resources-section-content">
                        <div className="resources-content">
                            <div className="resources-icon">
                                <HelpIcon/>
                            </div>
                            <div className="resources-text">
                                <Link href="https://sendlinx.com/support-help-faq/" target="_blank">Help Center</Link>
                                <p>Troubleshooting and how-tos</p>
                            </div>
                        </div>
                        <div className="resources-content">
                            <div className="resources-icon">
                                <QuestionAnswerIcon/>
                            </div>
                            <div className="resources-text">
                                <RouerLink to={"/user/settings"}></RouerLink>
                                <Link href="https://sendlinx.com/" target="_blank">Ask the community</Link>
                                <p>Sendlinx Support Community</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    render() {
        const { classes } = this.props;
        const { subject, message, open, validation } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <GridItem>
                        <h3 className={classes.title}>Support</h3>
                            <GridContainer>
                                <GridItem xs={12} sm={6} lg={5} className={classes.ProfileColumn}>
                                    <div className={classes.supportContainer}>
                                        <form onSubmit={this.handleSubmit} className={classes.formFields}>
                                            <div className="formElement">
                                                <input 
                                                    type="email" 
                                                    value={this.state.email} 
                                                    placeholder="From: user@example.com"
                                                    id="outlined-yourEmail"
                                                    name= "yourEmail"
                                                    onChange= {(e) => this.handleChange(e, 'email')}
                                                    disabled={true}
                                                >
                                                </input>
                                                {
                                                    validation.email === 'error' ?
                                                        <Clear className="claer-icon" />
                                                    :
                                                        <></>
                                                }
                                            </div>
                                            <div className="formElement">
                                                <input 
                                                    type="text" 
                                                    value={subject} 
                                                    placeholder="Subject"
                                                    id="outlined-subject"
                                                    name= "subject"
                                                    onChange= {(e) => this.handleChange(e, 'subject')}
                                                >
                                                </input>
                                                {
                                                    validation.subject === 'error' ?
                                                        <Clear className="claer-icon" />
                                                    :
                                                        <></>
                                                }
                                            </div>

                                            <div className="formElement">
                                                <textarea 
                                                    type="text" 
                                                    value={message} 
                                                    placeholder="Question"
                                                    id="outlined-message"
                                                    name= "message"
                                                    onChange= {(e) => this.handleChange(e, 'message')}
                                                >
                                                </textarea>
                                                {
                                                    validation.message === 'error' ?
                                                        <Clear className="claer-icon" />
                                                    :
                                                        <></>
                                                }
                                            </div>
                                            <div className={classes.formFooter}>
                                                <Button color="custom" type="submit">
                                                    <svg className="sendicon" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 3760 3500">
                                                        <path d="M970 3489c-52-16-86-45-108-92-19-39-178-874-169-883 8-8 888 629 884 640-3 12-423 297-472 320-43 21-95 26-135 15zm1322-152c-40-15-1104-787-1100-798 2-5 379-378 838-830C3215 542 3575 185 3559 195c-8 5-614 481-1348 1059-734 577-1339 1049-1345 1049-13 0-743-529-790-572-67-61-89-186-47-268 45-90-23-61 1743-738C2675 379 3472 74 3542 48c138-53 167-54 195-11 9 13 16 36 15 51-1 19-1046 2900-1130 3113-18 45-74 101-127 125-56 27-146 31-203 11z"/>
                                                    </svg> 
                                                    Send
                                                </Button>
                                            </div>
                                            <ContactConfirmationModal onClose={this.onOkClick} open={open} stor={this.store} history={this.history}/>
                                        </form>
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={6} lg={5} className={classes.supportResources}>
                                    { this.renderResources() }
                                </GridItem>
                            </GridContainer>
                        </GridItem>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserSupport.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userHomePageStyle)(UserSupport);
