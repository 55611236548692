import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch  } from "react-router-dom";
import PropTypes from "prop-types";
import {Helmet} from 'react-helmet';
import Navbar from './components/Navbar';
import LoaderComponent from './components/Loader';
// import RouterComponent from './components/Router';
import AuthApi from '../assets/js/utils/Auth';
import { helper } from '../assets/js/utils/Element';
import NewUpdate from './components/NewUpdate';
import GpdrBanner from "./components/GpdrBanner";
import withStyles from "@material-ui/core/styles/withStyles";
import SplashScreen from './components/SplashScreen';
import Home from './pages/Home';
import Download from './pages/Download';
import Help from "./pages/Help";
import Privacy from "./pages/Privacy";
import About from "./pages/About";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import SignupRequest from "./pages/auth/SignupRequest";
import BusinessSignup from "./pages/auth/BusinessSignup";
import BusinessInfo from "./pages/auth/BusinessInfo";
import Terms from "./pages/Terms";
import Success from "./pages/Success";
import Contact from "./pages/Contact";
import Logout from './components/Logout';
import UserHome from "./pages/user/Home";
import UserFavourites from "./pages/user/Favourites";
import UserTrashFiles from "./pages/user/TrashFiles";
import UserTrashLinks from "./pages/user/TrashLinks";
import UserSupport from "./pages/user/Support";
import Tutorials from "./pages/user/Tutorials";
import Downloads from "./pages/user/Downloads";
import Plans from "./pages/Plans";
import UserLinks from "./pages/user/Links";
import UserSubscription from "./pages/user/Subscription";
import UserBillingHistory from "./pages/user/BillingHistory";
import UserSend from "./pages/user/Send";
import UserSettings from "./pages/user/Settings";
import UserAccount from "./pages/user/Account";
// import UserPlans from "./pages/user/Plans"
import UserProfile from "./pages/user/Profile"
import BusinessProfile from "./pages/user/Business"
import AdManagement from "./pages/user/AdManagement"
import UserPassword from "./pages/user/Password"
import UserAccounts from "./pages/user/Accounts"
import UserPrivacy from "./pages/user/Privacy"
import UserExport from "./pages/user/Export"
import UserDownload from "./pages/user/Download"
import UserHistory from "./pages/user/History"
import BackgroundSlider from "./components/Slider/BackgroundSlider";
import UserShared from "./pages/user/Shared";
import SharedView from "./pages/user/SharedView";
import BasicSignup from "./pages/auth/BasicSignup";
import Users from "./pages/user/Users";
import OfficeHome from "./pages/user/OfficeHome";
import AttachmentRules from "./pages/user/AttachmentRules";
import UserArchives from "./pages/user/Archives";
import UsersArchivesTransfers from "./pages/user/ArchivesTransfers";
import CheckTransfers from "./components/CheckTransfers";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import EditDocument from "./pages/EditDocument";
import UserExtensions from "./pages/user/Extensions";
// import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import ExternalLinks from "./pages/user/ExternalLinks";
import ExternalLink from "./pages/ExternalLink";
import ErrorPage from "./pages/ErrorPage";
import Referrals from "./pages/user/Referrals";
import LoginVideos from "./pages/user/LoginVideos";
import Sidebar from "./components/User/Sidebar";
import SidebarBusiness from "./components/User/SidebarBusiness";
import AccountSidebar from "./components/User/AccountSidebar";
import '../assets/scss/App.scss';
import appStyle from "../assets/jss/appStyle.jsx";
import { blackColor, whiteColor, primaryColor, grayColor, infoColor } from '../assets/jss/material-kit-pro-react';

const history = createBrowserHistory();
history.listen(function (location) {
    window.scrollTo(0, 0);
});

class App extends Component {
    constructor(props) {
        super(props);
        this.store = this.props.store;
        this.loading = this.props.loading;
        this.state = this.store.getState();
        this.loaded = false;
    }
    componentDidMount(){
        this.loaded = true;

        const that = this;
        this.listenHistory();
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken === false){
            this.loading = false
            that.store.dispatch({type: 'UPDATE_STATE', state: {}}); 
        }
        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){
                let url = that.store.getState().url;
                if(isValidToken.account_type === -1 && url.indexOf("subscription") === -1){
                    url = "/user/plans";
                }
                that.store.dispatch({
                    type: "LOGIN",
                    state: {
                        authorized: true,
                        user: isValidToken,
                        url: url
                    }
                });
                if(isValidToken.account_type === -1 && url === "/user/plans"){
                    setTimeout(() => {
                        history.push(url);
                    }, 10);
                }
            }
        }).catch(err => {
            this.loading = false;
            that.store.dispatch({type: 'UPDATE_STATE',state: {}});
        });

        if(process.env.REACT_APP_ENV !== "production"){
            console.log(process.env);
        }

        const returnUrl = helper.getParam("rte");
        if(returnUrl && returnUrl.length > 0 && returnUrl !== "null"){
            this.store.dispatch({type: 'UPDATE_STATE',state: {url: "/"+decodeURIComponent(returnUrl) }});
            history.push(returnUrl);
        }
    }
    logout(){
        history.push("/auth/logout");
        AuthApi.logout();
    }
    listenHistory(){
        const that = this;
        history.listen(function (location) {
            that.store.dispatch({type: 'UPDATE_STATE',state: {url: location.pathname }});           
        });
    }
    getHeadScripts(){
        const { authorized } = this.store.getState();
        let headScripts = [];
        if(!authorized){
            headScripts.push({
                type: 'text/javascript',
                async: "async",
                defer: true,
                src: "https://www.google.com/recaptcha/api.js?onload=&render=explicit"
            });
        }

        return headScripts;
    }
    getUserTheme(){
        const storeState = this.store.getState();
        let user = storeState.user;
        let themeOptions = {
            palette: {
                primary: {
                    main: primaryColor[0],
                    light: grayColor[5],
                    dark: grayColor[20],
                    contrastText: whiteColor,
                },
                secondary: {
                    main: infoColor[0],
                    light: infoColor[6],
                    dark: infoColor[0],
                    contrastText: whiteColor,
                },
            },
            customize: {
                opacityColor: (user.theme === "standard" ? "rgba(0, 0, 0, 0.3)" : "transparent"),
                hoverColor: (user.theme === "standard" ? "rgba(0, 0, 0, 0.5)" : "transparent"),
                backgroundColor: (user.theme === "standard" ? "linear-gradient(180deg, #062240 0%, #1D212E 100%)" : user.themeSetting.backgroundColor),
                color: (user.theme === "standard" ? whiteColor : user.themeSetting.textColor),
                pageTitleColor: (user.theme === 'standard' ? whiteColor : user.themeSetting.pageTitleColor),
                pageTitleColorModal: (user.theme === 'standard' ? blackColor : user.themeSetting.pageTitleColor),
                border: (user.theme === "standard" ? "none" : "1px solid "+user.themeSetting.borderColor),
                borderColor: (user.theme === "standard" ? "#43444A" : user.themeSetting.borderColor),
                borderColorModal: (user.theme === "standard" ? grayColor[26] : user.themeSetting.borderColor),
                textShadow: (user.theme === "standard" ? "2px 2px 2px "+blackColor : "none"),
                loaderColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.loaderColor),
                iconColor: (user.theme === "standard" ? "#B4B5BF" : "#00000080"),
                iconsColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.iconsColor),
                modalBackground: (user.theme === "standard" ? "rgba(0, 0, 0, 0.7)" : whiteColor),
                sidebarBackgroundColor: (user.theme === "standard" ? "#212124" : whiteColor),
                sidebarMenuLinkColor: (user.theme === "standard" ? "#F0EDEA" : user.themeSetting.sidebarMenuLinkColor),
                sidebarSubMenuLinkColor: (user.theme === "standard" ? whiteColor : user.themeSetting.sidebarSubmenuLinkColor),
                sidebarMenuLinkShadow: (user.theme === "standard" ? "none" : "0 0 "+user.themeSetting.sidebarMenuLinkShadow),
                sidebarMenuIconsColor: (user.theme === "standard" ? "#F0EDEA" : user.themeSetting.sidebarMenuIconsColor),
                sidebarMenuWeight: (user.theme === "standard" ? "600" : "600"),
                sidebarMenuHoverColor: (user.theme === "standard" ? "rgba(255, 255, 255, 0.1)" : "rgba(86, 110, 194, 0.25)"),
                tableHeadBackgroundColor: (user.theme === "standard" ? "rgba(0, 0, 0, 0.7)": user.themeSetting.backgroundColor),
                tableHeadTextColor: (user.theme === "standard" ? whiteColor : user.themeSetting.tableHeadTextColor),
                tableHeadTextColorModal: (user.theme === "standard" ? grayColor[9] : user.themeSetting.tableHeadTextColor),
                tableRowActionsIconsColor: (user.theme === "standard" ? whiteColor : user.themeSetting.tableRowActionsIconsColor),
                checkboxColor: (user.theme === 'standard' ? whiteColor : user.themeSetting.checkboxColor),
                checkboxColorModal: (user.theme === 'standard' ? grayColor[9] : user.themeSetting.checkboxColorModal),
                breadcrumbsColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.breadcrumbsColor),
                linksColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.linksColor),
                headingsColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.headingsColor),
                buttonsColor: (user.theme === "standard" ? infoColor[0] : user.themeSetting.buttonsColor),
                blackWhiteColor: (user.theme === "standard" ? blackColor : whiteColor),
                customTabsTextColor: (user.theme === "standard" ? "#B4B5BF" : "#00000080"),
                customTabsActiveTextColor: (user.theme === "standard" ? "#B4B5BF" : "#000"),
                horizontalMenuTextColor: (user.theme === "standard" ? whiteColor : user.themeSetting.horizontalMenuTextColor),
                horizontalMenuIconsColor: (user.theme === "standard" ? infoColor[0]: user.themeSetting.horizontalMenuIconsColor),
                horizontalMenuBgColor: (user.theme === "standard" ? "#253054" : whiteColor),
                horizontalMenuShadowColor: (user.theme === "standard" ? "0px 6px 6px 0px #00000040" : "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)"),
                searchBgColor: (user.theme === "standard" ? "#253054" : "#EEEEEE"),
                searchActiveBgColor: (user.theme === "standard" ? "#253054" : whiteColor),
                searchBorderColor: (user.theme === "standard" ? "#43444A" : "#556DC24D")
            }
        };
        const muiTheme = createMuiTheme(themeOptions);
        return muiTheme;
    }
    getappBackgroundCss(){
        let userTheme = this.getUserTheme();
        let backgroundColor = userTheme.customize.backgroundColor;

        let backgroundCss = `
            .App.light:before {
                background: `+(backgroundColor)+`;
            }
        `;

        return backgroundCss;
    }
    render() {
        const { mainLoading, url, authorized, user } = this.store.getState();
        const { classes } = this.props;
        const isTransparentHeader = helper.isTransparentHeader(url);
        const bodyClass = helper.getBodyClass(url);
        const isDocumentEditPage = helper.isDocumentEditPage(url);

        if(mainLoading){
            return <LoaderComponent />
        }
        if(this.loading === true){
           return <SplashScreen />
        }
        let isStaticPage = false;
        const path = history.location.pathname;
        if(path === "/help" || path === "/help/about" || path === "/legal/terms" || path === "/privacy" || path === "/plans" || path === "/contact" || isDocumentEditPage){
            isStaticPage = true;
        }
        const headScripts = this.getHeadScripts();
        const appBackgroundCss = this.getappBackgroundCss();
        return (
            <MuiThemeProvider theme={this.getUserTheme()}>
                <div className={classes.mainAppResponsiv+" "+bodyClass+" App "+user.theme+" "+(isStaticPage ? classes.staticPage : classes.backgroundOverlay)+(authorized?' user_authorized':'')+(isTransparentHeader?" app_transparent_header":"")}>
                    <div className="main-container" id="global_wrapper">
                        <Router history={history}>
                            {
                                isDocumentEditPage && this.loaded === true ?
                                    <div id="global_content">
                                        <Switch>
                                            <Route exact path="/document/edit/:id" component={({match}) => <EditDocument store={this.store} history={history} match={match} />} />
                                            <Route exact path="/document/editnew/:id" component={({match}) => <EditDocument store={this.store} history={history} match={match} action="editnew" />} />
                                        </Switch>
                                    </div>
                                :
                                <>
                                    <div className={classes.mainContent}>
                                        {
                                            helper.showSidebar(url, user, authorized) ?
                                                <div className={"leftbar_content "+classes.sidebar}>
                                                    {
                                                        helper.showBusinessSidebar(url) ?
                                                            <SidebarBusiness store={this.store}/>
                                                        : helper.showAccountSidebar(url, user) ?
                                                            <AccountSidebar store={this.store}/>
                                                        :
                                                            <Sidebar store={this.store}/>
                                                    }
                                                </div>
                                            :
                                                <></>
                                        }
                                        <div className='rigth_content'>
                                            <Navbar store={this.store} history={history} color="info" isResponsive={true} />
                                            <div id="global_content">
                                                <Switch>
                                                    {
                                                        authorized && user.account_type === -1 ?
                                                            <>
                                                                <Route exact path="/auth/logout" component={ () => <Logout store={this.store} history={history} />} />
                                                                <Route exact path="/user/subscription" component={ () => <UserSubscription store={this.store} history={history} />} />
                                                                <Route exact path="*" component={ () => <Plans store={this.store} history={history} />} />
                                                            </>
                                                        :
                                                        null
                                                    }
                                                    <Route exact path="/" component={() => <Home store={this.store} history={history} />} />
                                                    <Route exact path="/downloads/:id" component={({match}) => <Download store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/external-links/:id" component={({match}) => <ExternalLink store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/downloads/:id/:recipient" component={({match}) => <Download store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/success" component={() => <Success store={this.store} history={history} />} />
                                                    <Route exact path="/help" component={() => <Help store={this.store} history={history} />} />
                                                    <Route exact path="/help/about" component={() => <About store={this.store} history={history} />} />
                                                    <Route exact path="/auth/login" component={() => <Login store={this.store} history={history} />} />
                                                    <Route exact path="/auth/forgot" component={() => <Login store={this.store} history={history} forgot={true} />} />
                                                    <Route exact path="/signup/business" component={() => <BusinessSignup store={this.store} history={history} />} />
                                                    <Route exact path="/signup/business/:inviteCode" component={(match) => <BusinessSignup store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/signup" component={(match) => <Signup store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/signup/request" component={() => <SignupRequest store={this.store} history={history} />} />
                                                    <Route exact path="/signup/:signupCode/:email/:planId" component={({match}) => <Signup store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/referral/signup/:inviteCode" component={(match) => <Signup store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/user/business-info" component={() => <BusinessInfo store={this.store} history={history} />} />
                                                    <Route exact path="/plans" component={() => <Plans store={this.store} history={history} />} />
                                                    <Route exact path="/contact" component={() => <Contact store={this.store} history={history} />} />
                                                    <Route exact path="/legal/terms" component={() => <Terms store={this.store} history={history} />} />
                                                    <Route exact path="/privacy" component={() => <Privacy store={this.store} history={history} />} />
                                                    <Route exact path="/auth/logout" component={ () => <Logout store={this.store} history={history} />} />
                                                    <Route exact path="/user/home" component={ () => <UserHome store={this.store} history={history} />} />
                                                    <Route exact path="/user/home/connect/docusign" component={ () => <UserHome store={this.store} history={history} />} />
                                                    <Route exact path="/user/files/:action/:file" component={ (match) => <OfficeHome store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/user/archives" component={ () => <UserArchives store={this.store} history={history} />} />
                                                    <Route exact path="/user/archives-transfers" component={ () => <UsersArchivesTransfers store={this.store} history={history} />} />
                                                    <Route exact path="/user/trash/files" component={ () => <UserTrashFiles store={this.store} history={history} />} />
                                                    <Route exact path="/user/trash/links" component={ () => <UserTrashLinks store={this.store} history={history} />} />
                                                    <Route exact path="/user/favourites" component={ () => <UserFavourites store={this.store} history={history} />} />
                                                    <Route exact path="/user/support" component={ () => <UserSupport store={this.store} history={history} />} />
                                                    <Route exact path="/user/tutorials" component={ () => <Tutorials store={this.store} history={history} />} />
                                                    <Route exact path="/user/downloads" component={ () => <Downloads store={this.store} history={history} />} />
                                                    <Route exact path="/user/links" component={ () => <UserLinks store={this.store} history={history} />} />
                                                    <Route exact path="/user/external-links" component={ () => <ExternalLinks store={this.store} history={history} />} />
                                                    <Route exact path="/user/subscription" component={ () => <UserSubscription store={this.store} history={history} />} />
                                                    <Route exact path="/user/billinghistory" component={ () => <UserBillingHistory store={this.store} history={history} />} />
                                                    <Route exact path="/user/send" component={ () => <UserSend store={this.store} history={history} />} />
                                                    <Route exact path="/user/settings" component={ () => <UserSettings store={this.store} history={history} />} />
                                                    <Route exact path="/user/account" component={ () => <UserAccount store={this.store} history={history} />} />
                                                    <Route exact path="/user/plans" component={ () => <Plans store={this.store} history={history} />} />
                                                    <Route exact path="/user/profile" component={ () => <UserProfile store={this.store} history={history} />} />
                                                    <Route exact path="/user/business" component={ () => <BusinessProfile store={this.store} history={history} />} />
                                                    <Route exact path="/user/ad-management" component={ () => <AdManagement store={this.store} history={history} />} />
                                                    <Route exact path="/user/password" component={ () => <UserPassword store={this.store} history={history} />} />
                                                    <Route exact path="/user/accounts/license" component={ () => <UserAccounts store={this.store} history={history} tab={0} />} />
                                                    <Route exact path="/user/accounts" component={ () => <UserAccounts store={this.store} history={history} tab={1} />} />
                                                    <Route exact path="/user/accounts/other" component={ () => <UserAccounts store={this.store} history={history} tab={2} />} />
                                                    <Route exact path="/user/accounts/install" component={ () => <UserAccounts store={this.store} history={history} tab={3} />} />
                                                    <Route exact path="/user/privacy" component={ () => <UserPrivacy store={this.store} history={history} />} />
                                                    <Route exact path="/user/export" component={ () => <UserExport store={this.store} history={history} />} />
                                                    <Route exact path="/user/download" component={ () => <UserDownload store={this.store} history={history} />} />
                                                    <Route exact path="/user/history" component={ () => <UserHistory store={this.store} history={history} />} />
                                                    <Route exact path="/user/shared" component={ (match) => <UserShared store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/user/shared/:id" component={ (match) => <UserShared store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/user/shared/transfer/:transferId" component={ (match) => <UserShared store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/basic/signup/:id/:email" component={({match}) => <BasicSignup store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/shared/view/:token/:id" component={ (match) => <SharedView store={this.store} history={history} match={match} />} />
                                                    <Route exact path="/users" component={ () => <Users store={this.store} history={history} />} />
                                                    <Route exact path="/videos" component={ () => <LoginVideos store={this.store} history={history} />} />
                                                    <Route exact path="/attachment/rules" component={ () => <AttachmentRules store={this.store} history={history} />} />
                                                    <Route exact path="/user/extensions" component={ () => <UserExtensions store={this.store} history={history} />} />
                                                    <Route exact path="/user/extensions/connect/:extension" component={ () => <UserExtensions store={this.store} history={history} />} />
                                                    <Route exact path="/error" component={ (match) => <ErrorPage store={this.store} history={history} />} />
                                                    <Route exact path="/user/referrals" component={ () => <Referrals store={this.store} history={history} />} />
                                                    <Route exact path="*" component={() => <Home store={this.store} history={history} />} />
                                                </Switch>
                                                {/* <Footer /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <GpdrBanner />
                                    <NewUpdate domain="sendlinx" />
                                    <CheckTransfers store={this.store} />
                                </>
                            }
                            
                        </Router>
                    </div>
                    {
                        user.theme === "standard" && !isDocumentEditPage ?
                            <div className={classes.bgSlider+" bg_slider"}>
                                <BackgroundSlider store={this.store} />
                            </div>
                        :
                        <></>
                    }
                    <Helmet 
                        htmlAttributes={{
                            class: bodyClass+' '+(authorized? 'logged-in':'nonlogged-in')
                        }}
                        script={headScripts}
                    />
                    <Helmet>
                        <style type="text/css">
                            {appBackgroundCss}
                        </style>
                    </Helmet>
                </div>
            </MuiThemeProvider>
        );
    }
}

App.defaultProps = {
    loading: false
}
App.propTypes = {
    loading: PropTypes.bool,
}

export default withStyles(appStyle)(App);