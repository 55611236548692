import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import Home from "../Home";

import withStyles from "@material-ui/core/styles/withStyles";
import userSendPageStyle from "../../../assets/jss/user/userSendPageStyle.jsx";

const UserSend = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }
         }
    }
    render() {
        const { classes } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <Home store={this.props.store} history={this.props.history} />
            )
        }
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <Home store={this.props.store} history={this.props.history} slider={false} />
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserSend.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userSendPageStyle)(UserSend);
