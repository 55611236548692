import {
    container,
    defaultFont,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    transition,
    boxShadow,
    drawerWidth,
    blackColor,
    whiteColor,
    grayColor,
    hexToRgb
} from "../../../jss/material-kit-pro-react.jsx";

import customCheckboxRadioSwitchStyle from "../../material-kit-pro-react/customCheckboxRadioSwitchStyle.jsx";
const headerStyle = theme => ({
    ...customCheckboxRadioSwitchStyle,
    appBar: {
        display: "flex",
        border: "0",
        borderRadius: "3px",
        padding: "0px",
        marginBottom: "20px",
        color: grayColor[15],
        width: "100%",
        backgroundColor: whiteColor,
        boxShadow:
        "0 4px 18px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 7px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.15)",
        transition: "all 150ms ease 0s",
        alignItems: "center",
        flexFlow: "row nowrap",
        justifyContent: "flex-start",
        position: "relative",
    },
    absolute: {
        position: "absolute",
        top: "auto"
    },
    fixed: {
        position: "fixed",
        [theme.breakpoints.up("md")]: {
            position: "static",
            zIndex: "unset",
            margin: "0px"
        },
        [theme.breakpoints.down("sm")]: {
            left: "40px",
            width: "calc(100% - 40px)"
        }
    },
    container: {
        ...container,
        minHeight: "50px",
        alignItems: "center",
        justifyContent: "space-between",
        display: "flex",
        flexWrap: "nowrap",
        maxWidth: "100% !Important",
        [theme.breakpoints.up("md")]: {
            minHeight: "0px"
        }
    },
    title: {
        letterSpacing: "unset",
        "&,& a": {
            ...defaultFont,
            minWidth: "unset",
            lineHeight: "30px",
            fontSize: "18px",
            borderRadius: "3px",
            textTransform: "none",
            whiteSpace: "nowrap",
            color: "inherit",
            "&:hover,&:focus": {
                color: "inherit",
                background: "transparent"
            }
        }
    },
    appResponsive: {
        margin: "20px 10px",
        marginTop: "0px"
    },
    primary: {
        backgroundColor: primaryColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.46)"
    },
    info: {
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(infoColor[0]) +
        ", 0.46)",
        borderRadius: "0px"
    },
    success: {
        backgroundColor: successColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.46)"
    },
    warning: {
        backgroundColor: warningColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.46)"
    },
    danger: {
        backgroundColor: dangerColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(dangerColor[0]) +
        ", 0.46)"
    },
    rose: {
        backgroundColor: roseColor[0],
        color: whiteColor,
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.46)"
    },
    transparent: {
        backgroundColor: "transparent !important",
        boxShadow: "none",
        paddingTop: "0px",
        color: whiteColor
    },
    dark: {
        color: whiteColor,
        backgroundColor: grayColor[9] + " !important",
        boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 12px -5px rgba(" +
        hexToRgb(grayColor[9]) +
        ", 0.46)"
    },
    white: {
        border: "0",
        padding: "0px",
        marginBottom: "20px",
        color: grayColor[15],
        backgroundColor: whiteColor + " !important",
        boxShadow:
        "0 4px 18px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 7px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.15)"
    },
    drawerPaper: {
        border: "none",
        bottom: "0",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        width: drawerWidth,
        ...boxShadow,
        position: "fixed",
        display: "block",
        top: "0",
        height: "100vh",
        right: "0",
        left: "auto",
        visibility: "visible",
        overflowY: "visible",
        borderTop: "none",
        textAlign: "left",
        paddingRight: "0px",
        paddingLeft: "0",
        ...transition
    },
    hidden: {
        width: "100%"
    },
    collapse: {
        [theme.breakpoints.up("md")]: {
            display: "flex !important",
            MsFlexPreferredSize: "auto",
            flexBasis: "auto"
        },
        WebkitBoxFlex: "1",
        MsFlexPositive: "1",
        flexGrow: "1",
        WebkitBoxAlign: "center",
        MsFlexAlign: "center",
        alignItems: "center"
    },
    closeButtonDrawer: {
        position: "absolute",
        right: "15px",
        top: "7px",
        zIndex: "1"
    },
    brand: {
        padding: '6px 0px',
        "& img": {
            maxHeight: "50px"
        },
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            paddingTop: "0px",
            top: "6px",
            zIndex: "1100"
        },
        "&.mobile-logo": {
            [theme.breakpoints.up("md")]: {
                display: "none"
            }
        },
        [theme.breakpoints.down("xs")]: {
            padding: "10px 0px",
            "& img": {
                maxHeight: "40px",
            }
        }
    },
    userFirstLetter: {
        padding: "0px",
        "& .user-icon": {
            width: "36px",
            height: "36px",
            backgroundColor: grayColor[7],
            color: whiteColor,
            borderRadius: "100%",
            border: "none",
            fontSize: "14px",
            lineHeight: "36px",
            display: "inline-block",
            fontWeight: "600",
            "& img.profile-picture": {
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "100%",
                verticalAlign: "top"
            },
        },
    },
    updatesIcon: {
        marginRight: "25px",
        "& svg": {
            display: "block",
        }
    },
    supportIcon: {
        cursor: "pointer",
        marginRight: "25px",
        "& svg": {
            display: "block",
        }
    },
    headerIconSection: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        "& svg": {
            color: theme.customize.iconColor,
            fill: theme.customize.iconColor,
        },
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            top: "18px",
            right: "15px",
            zIndex: "1",
        }
    },
    toggleIcon: {
        padding: "0px",
        marginLeft: "20px"
    },
    popupContent: {
        // "&.active": {
        //     display: "block",
        // },
        // display: "none",
        // position: "absolute",
        // background: whiteColor,
        // border: "1px solid "+grayColor[7],
        // boxShadow:
        // "0 2px 10px  rgba(" +
        // hexToRgb(blackColor) +
        // ", 0.2)",
        // borderColor: "rgba(" +
        // hexToRgb(blackColor) +
        // ", 0.2)",
        // right: "25px",
        // top: "60px",
        color: blackColor,
        width: "100%",
        borderRadius: "5px",
        "& .top-content": {
            padding: "20px 30px 0px",  
            [theme.breakpoints.down("md")]: {
                padding: "20px 20px 0px",  
            },
            textAlign: "Center",
            "& .user-manage": {
                border: "1px solid "+ grayColor[11]
            },
            "& .user-image": {
                marginBottom: "15px",
                display: "inline-block",
                position: "relative",
                "& .uploadPhotoInput": {
                    "&  .MuiFormControl-root":{
                        margin: "0px",
                        padding: "0px",
                        "& .MuiInput-formControl":{
                            "&:before": {
                                display: "none"
                            }
                        },
                        "& input": {
                            display: "none"
                        }
                    }
                },
                "& button": {
                    position: "absolute",
                    backgroundColor: whiteColor,
                    bottom: "0px",
                    right: "0px",
                    padding: "5px",
                    width: "auto",
                    height: "auto",
                    minWidth: "auto",
                    margin: "0px",
                    "& svg": {
                        width: "16px",
                        height: "16px",
                        color: grayColor[7]
                    }
                },
                "& .user-icon": {
                    width: "80px",
                    height: "80px",
                    lineHeight: "80px",
                    fontSize: "40px",
                    fontWeight: "bold",
                    "& .sd_loader": {
                        height: "80px"
                    },
                },
                "& .removePhoto": {
                    "& button": {
                        bottom: "23px",
                        left: "0px",
                        right: "auto"
                    }
                }
            },
            "& .user-email": {
                wordBreak: "break-all"
            },
            "& h4": {
                margin: "0px",
                lineHeight: "normal",
            },
            "& p": {
                marginBottom: "5px",
            },
            "& .stats-section": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "10px",
            },
            "& .left-stats": {
                marginRight: "30px",
                "& .graph-stats": {
                    // width: "70px",
                    height: "70px",
                    //border: "1px solid "+grayColor[11],
                    margin: "0px auto",
                    borderRadius: "100%",
                    "& span": {
                        width: "100%",
                        height: "100%",
                        display: "block",
                        backgroundColor: infoColor[0],
                        borderRadius: "100%",
                        border: "3px solid "+whiteColor,
                    },
                    "& .ct-chart": {
                        width: "67px",
                        height: "67px",
                        margin: "0px auto",
                        "& .ct-label": {
                            fill: whiteColor
                        },
                        "& .ct-series-b .ct-slice-pie": {
                            fill: grayColor[13]
                        },
                        "& .ct-series-a .ct-slice-pie": {
                            fill: infoColor[0]
                        }
                    }
                },
                "& .data-detail": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "6px",
                    "& span": {
                        fontSize: "12px",
                        lineHeight: "normal",
                        "&.used-data": {
                            color: theme.customize.linksColor,
                            marginRight: "5px",
                            paddingRight: "5px",
                            borderRight: "1px solid "+theme.customize.borderColorModal,
                        },
                    }
                }
            },
            "& .right-stats": {
                maxWidth: "110px",
                width: "100%",
                "& ul":{
                    listStyleType: "none",
                    padding: "0px",
                    margin: "0px",
                    "& ul": {
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "12px",
                        lineHeight: "normal",
                        marginBottom: "4px",
                        "& .stat-name": {
                            display: "flex",
                            "& span": {
                                width: "12px",
                                height: "12px",
                                display: "inline-block",
                                marginRight: "5px",
                                backgroundColor: grayColor[7],
                                "&.web": {
                                    backgroundColor: "#17965b",
                                },
                                "&.outlook": {
                                    backgroundColor: "#4374dd",
                                },
                                "&.app": {
                                    backgroundColor: "#7f3897",
                                },
                                "&.uploads": {
                                    backgroundColor: "#4071e7",
                                },
                                "&.my_docs": {
                                    backgroundColor: "#ff0303",
                                }
                            }
                        }
                    }
                }
            },
            "& .signup-btn": {
                marginBottom: "-25px",
            },
        },
        "& .bottom-content": { 
            "& ul": {
                display: "flex",
                padding: "0px",
                listStyleType: "none",
                justifyContent: "center",
                borderTop: "1px solid "+grayColor[11],
                paddingTop: "25px",
                paddingBottom: "10px",
                margin: "0px",
                "& >li:first-child": {
                    "&:after": {
                        content: '""',
                        width: "3px",
                        height: "3px",
                        borderRadius: "100%",
                        backgroundColor: blackColor,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginTop: "2px",
                        marginLeft: "10px",
                        marginRight: "10px"
                    }
                },
                "& a": {
                    color: blackColor,
                    fontSize: "12px",
                    "&:hover": {
                        textDecoration: "none"
                    }
                }
            }
        },
    },
    popoverRoot: {
        "& .MuiPaper-root": {
            left: "auto !important",
            right: "20px !important",
            transformOrigin: "unset !important",
            maxWidth: "365px",
            width: "100%",
            boxShadow: "0px 10px 20px 0px #0000001A",
        }
    },
    scheduledDeletion: {
        position: "absolute",
        left: "0px",
        right: "0px",
        width: "430px",
        margin: "0px auto",
        "@media (max-width: 800px)": {
            left: "15px",
            textAlign: "left",
            top: "60px",
            right: "auto"
        }
    },
    label: {
        ...customCheckboxRadioSwitchStyle.label,
        paddingLeft: "0px",
        color: theme.customize.color
    },
    uncheckedIcon: {
        ...customCheckboxRadioSwitchStyle.uncheckedIcon,
        borderColor: theme.customize.checkboxColor,
    },
    checkedIcon: {
        ...customCheckboxRadioSwitchStyle.checkedIcon,
        borderColor: "#2171C6",
        background: "#2171C6",
        fill: whiteColor,
    },
    switchLayoutIcon: {
        marginRight: "12px",
        "& .MuiSwitch-root": {
            width: "auto",
            height: "auto",
            padding: "6px",
        }
    },
    switchBase: {
        ...customCheckboxRadioSwitchStyle.switchBase,
        padding: "3px",
        "&.Mui-checked": {
            transform: "translateX(18px)",
        }
    },
    switchBar: {
        ...customCheckboxRadioSwitchStyle.switchBar,
        width: "28px",
        height: "12px"
    },
    switchIcon: {
        ...customCheckboxRadioSwitchStyle.switchIcon,
        backgroundColor: "black",
        width: "18px",
        height: "18px",
        "&:before": {
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            content: '""',
            position: "absolute",
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent('#fff',)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
        }
    },
    switchChecked: {
        ...customCheckboxRadioSwitchStyle.switchChecked,
        "& $switchIcon": {
            "&:before": {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent('#fff',)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
            }
        }
    },
    supportPopupContent: {
        color: blackColor,
        width: "100%",
        borderRadius: "5px",
        padding: "10px 15px",
        "& a": {
            cursor: "pointer",
        }
    },
    supportPopoverRoot: {
        "& .MuiPaper-root": {
            left: "auto !important",
            right: "140px !important",
            transformOrigin: "unset !important",
            maxWidth: "145px",
            width: "100%",
            boxShadow: "0px 10px 20px 0px #0000001A",
        },
        "& ul": {
            padding: "0px",
            margin: "0px",
            listStyleType: "none",
        },
        "& .support-link": {
            margin: "0px",
            textDecoration: "none",
            padding: "5px 0px",
            display: "flex",
            alignItems: "flex-start",
            "& svg": {
                width: "14px",
                height: "14px",
                marginRight: "14px",
            },
            "& .label": {
                fontSize: "12px",
                color: blackColor,
                textTransform: "capitalize",
                lineHeight: "normal",
                fontWeight: "normal",
            }
        }
    },
});

export default headerStyle;
