import React from "react";
import Cookie from "../../../assets/js/utils/Cookie";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "../CustomButtons/Button";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import officeFileModalStyle from "../../../assets/jss/user/officeFileModalStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" timeout={500} ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const PdfFileModal = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        this.modelBodyRef = React.createRef();
        this.formRef = React.createRef();

        this.state = {
            file: this.props.file
        };

        this.resizeIframe.bind(this);
    }
    componentDidMount(){
        setTimeout(() => {
            this.resizeIframe();
        }, 100);
        window.addEventListener("resize", this.resizeIframe.bind(this));
    }
    componentWillUnmount(){
        window.removeEventListener("resize", this.resizeIframe);
    }
    resizeIframe(){
        try{
            if(this.modelBodyRef && this.modelBodyRef.current !== null){
                this.modelBodyRef.current.style.width = window.innerWidth+"px";
                this.modelBodyRef.current.style.height = window.innerHeight+"px";
            }
        }catch(e){}
    }
    onClose(){
        window.removeEventListener("resize", this.resizeIframe);
        this.props.onClose();
    }
    render() {
        const { user } = this.store.getState();
        const { classes } = this.props;
        const { file } = this.state;
        let expiresAt = Cookie.read("expires_at");
        if(!expiresAt || expiresAt === "null"){
            expiresAt = 0;
        }
        return (
                <Dialog
                    classes={{
                        root: classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={this.props.open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-labelledby="officeFileModal-slide-title"
                    aria-describedby="officeFileModal-slide-description"
                    onClose={() => this.onClose()}
                >
                    <DialogContent
                        id="officeFileModal-slide-description"
                        className={classes.modalBody}
                        ref={this.modelBodyRef}
                    >
                        <div className={classes.mainContainer+" "+classes.expandedMainContainer}>
                            <div className={classes.middleContainer}>
                                <div className={classes.topContainer}>
                                    <Button color="custom" onClick={() => this.onClose()} className={classes.backButton} justIcon>
                                        <ArrowBack />
                                    </Button>
                                    <div className={classes.basicFileDetails}>
                                        {file.name}
                                    </div>
                                </div>
                                <div className={classes.officeIframeContainer} id="frameholder">
                                    <iframe src={(file.downloadLink)+"?pdf=1&viewerId="+(user.id)} id="office_frame" name="office_frame" title="Pdf Frame" allowFullScreen>

                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </DialogContent>      
                </Dialog>
        );
    }
};

PdfFileModal.defaultProps = {
    open: false,
};
PdfFileModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    file: PropTypes.object,
};
export default withStyles(officeFileModalStyle)(PdfFileModal);
