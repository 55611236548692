import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import OfficeFileModal from "../../components/User/OfficeFileModal";

import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";
import { helper } from '../../../assets/js/utils/Element.js';

const OfficeHome = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        const params = this.props.match.match.params;
        this.state = {
            officeFileModal: true,
            officeFileAction: params.action,
            file: {
                id: params.file
            }
        };
    }
    onClose(){
        const { user } = this.store.getState();

        this.setState({officeFileModal: false});
        this.history.push(helper.getHomePageUrl(user));
    }
    render() {
        const { classes } = this.props;
        const { file, officeFileModal, officeFileAction } = this.state;
        const { authorized, user } = this.store.getState();
        if(!authorized || user.level_id === 5){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        
        return (
            <div className={classes.main}>
                {
                    officeFileModal ?
                        <OfficeFileModal 
                            store={this.store}
                            open={officeFileModal} 
                            action={officeFileAction}
                            onClose={() => this.onClose()}
                            file={file}
                        />
                    :
                    null
                }
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

OfficeHome.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(userHomePageStyle)(OfficeHome);
