import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import moment from 'moment';
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import LoaderComponent from "../../components/Loader";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteConfirmationModal from "../../components/User/DeleteConfirmationModal";
import ConfirmationModal from "../../components/User/ConfirmationModal";
import ChangeExpiryDateModal from "../../components/User/ChangeExpiryDateModal";
import ChangeLinkPasswordModal from "../../components/User/ChangeLinkPasswordModal";
import AttachmentsModal from  "../../components/User/AttachmentsModal";
import LinkDetailModal from  "../../components/User/LinkDetailModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import SummaryView from "../../components/User/SummaryView";
import FullView from "../../components/User/FullView";
import MobileView from "../../components/User/MobileView";
import MaterialIcon from "@mdi/react";
import { mdiLinkVariant, mdiPencil, mdiContentCopy, mdiDeleteOutline, mdiCloseCircleOutline, mdiClockCheckOutline,
    mdiFileDocumentMultipleOutline, mdiDotsVertical } from '@mdi/js';
import SearchInput from "../../components/User/SearchInput";
import ProcessingModal from "../../components/User/ProcessingModal";
import NavPills from "../../components/NavPills/NavPills.jsx";
import ExternalLinks from "./ExternalLinks";

import allLinksIcon from "../../../assets/img/linkmanager_all_icon.png";
// import desktopLinksIcon from "../../../assets/img/linkmanager_desktop_icon.png";
import outlookLinksIcon from "../../../assets/img/linkmanager_outlook_icon.png";
import uploadLinksIcon from "../../../assets/img/linkmanager_upload_icon.png";
import webLinksIcon from "../../../assets/img/linkmanager_web_icon.png";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import withStyles from "@material-ui/core/styles/withStyles";
import linksPageStyle from "../../../assets/jss/user/linksPageStyle.jsx";
const history = createBrowserHistory();

const UserLinks = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;

        let links = helper.getParam("links");
        if(!links || links === "null"){
            links = null;
        }else{
            links = links.split(",");
        }
        let files = helper.getParam("files");
        if(!files || files === "null"){
            files = null;
        }else{
            files = files.split(",");
        }
        let recipientEmail = helper.getParam("recipientEmail");
        if(!recipientEmail || recipientEmail === "null"){
            recipientEmail = null;
        }

        let viewType = 'summary';
        if(helper.detectMobile() || window.innerWidth <= 960){
            viewType = 'mobile';
        }
        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            changeLinkModal: false,
            changingLink: false,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
            transferId: null,
            expire: null,
            snackbarOpen: false,
            deleteLinkModal: false,
            deletingLink: false,
            filter: "",
            checked: [],
            links: links,
            files: files,
            attachmentsModal: false,
            attachmentFiles: [],
            viewType: viewType,
            searchFormData: this.defaultSearchFormData(),
            linkPassword: null,
            checkedAll: false,
            linkProcessingModal: false,
            linkDetailModal: false,
            linkDetail: null,
            activeTab: 0,
            viewPasswords: [],
            recipientEmail: recipientEmail
        };

        this.onChangeLinkModal = this.onChangeLinkModal.bind(this);
        this.onChangeLinkModalSuccess = this.onChangeLinkModalSuccess.bind(this);
        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized, user } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            if(user.level_id === 5){
                let redirectUrl = "/user/shared";
                this.history.push(redirectUrl);
            }

            const location = this.history.location;
            let searchFormData = null;
            if(location.state){
                searchFormData = location.state;
            }
            if(searchFormData !== null){
                this.onSearchInputSuccess(searchFormData.searchFormData);
            }else{
                this.loadLinks();
            }
        }

        window.addEventListener("resize", this.resizeFunction.bind(this));
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled');
            window.removeEventListener("resize", this.resizeFunction);
        }
    }
    resizeFunction(){
        if(helper.detectMobile() || window.innerWidth <= 960){
            this.setState({
                viewType: 'mobile'
            })
        }
    }
    defaultSearchFormData(){
        return {
            search: '',
            fileName: '',
            searchIn: '',
            attachmentContains: '',
            from: '',
            to: '',
            subject: '',
            destination: '',
            startDate: '',
            endDate: '',
            expiryStartDate: '',
            expiryEndDate: '',
            expired: false,
            notExpired: false,
            downloaded: false,
            notDownloaded: false,
            changedLinks: false
        }
    }
    clearSearch(){
        this.setState({
            searchFormData: this.defaultSearchFormData()
        }, () => {
            this.loadLinks()
        });
    }
    onSearchInputSuccess(searchData){
        const location = this.history.location;
        const pathname = location.pathname;

        if(pathname === '/user/links' && searchData.searchIn !== 'link-manager' && searchData.searchIn !== ''){
            this.history.push({
                pathname: '/user/home',
                search: '',
                state: { searchFormData: searchData }
            });
            return;
        }

        this.setState({
            searchFormData: {
                search: searchData.search,
                fileName: searchData.fileName,
                searchIn: searchData.searchIn,
                attachmentContains: searchData.attachmentContains,
                from: searchData.from,
                to: searchData.to,
                subject: searchData.subject,
                destination: searchData.destination,
                expired: searchData.expired,
                notExpired: searchData.notExpired,
                downloaded: searchData.downloaded,
                notDownloaded: searchData.notDownloaded,
                startDate: searchData.startDate,
                endDate: searchData.endDate,
                expiryStartDate:  searchData.expiryStartDate,
                expiryEndDate:  searchData.expiryEndDate,
            }
        }, () => {
            this.loadLinks();
        });
    }
    loadLinks(viewMore = false, filter = null){
        const source = axios.CancelToken.source();
        let { page, links, files, searchFormData, recipientEmail } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        if(filter === null){
            filter = this.state.filter;
        }
        let requestData = {
            page: page,
            limit: 200,
            filter: filter
        };
        let url = "/user/links?page="+page;
        if(links !== null){
            requestData['transfers'] = links;
            url += "&links="+links.join(",");
        }
        if(files !== null){
            requestData['files'] = files;
            url += "&files="+files.join(",");
        }
        if(recipientEmail !== null){
            requestData['to'] = recipientEmail;
            url += "&recipientEmail="+recipientEmail;
        }

        // search for request data
        if(searchFormData.search){
            requestData['search'] = searchFormData.search;
        }
        if(searchFormData.fileName){
            requestData['name'] = searchFormData.fileName;
        }
        if(searchFormData.from){
            requestData['from'] = searchFormData.from;
        }
        if(searchFormData.to){
            requestData['to'] = searchFormData.to;
        }
        if(searchFormData.subject){
            requestData['subject'] = searchFormData.subject;
        }
        if(searchFormData.destination){
            requestData['destination'] = searchFormData.destination;
        }
        if(searchFormData.expired){
            requestData['expired'] = searchFormData.expired;
        }
        if(searchFormData.notExpired){
            requestData['notExpired'] = searchFormData.notExpired;
        }
        if(searchFormData.downloaded){
            requestData['downloaded'] = searchFormData.downloaded;
        }
        if(searchFormData.notDownloaded){
            requestData['notDownloaded'] = searchFormData.notDownloaded;
        }
        if(searchFormData.startDate){
            requestData['startDate'] = searchFormData.startDate;
        }
        if(searchFormData.endDate){
            requestData['endDate'] = searchFormData.endDate;
        }
        if(searchFormData.expiryStartDate){
            requestData['expiryStartDate'] = searchFormData.expiryStartDate;
        }
        if(searchFormData.expiryEndDate){
            requestData['expiryEndDate'] = searchFormData.expiryEndDate;
        }

        history.push(url);
        Api.getLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            changeLinkModal: false,
            changingLink: false,
            changeExpiryDateModal: false,
            changingExpiryDate: false,
            changeLinkPasswordModal: false,
            changingLinkPassword: false,
            deleteLinkModal: false,
            deletingLink: false,
            filter: filter,
            checked: [],
            checkedAll: false
        });
    }
    onAttachmentsModal(status = false, files = []){
        this.setState({
            attachmentsModal: status,
            attachmentFiles: files
        })
    }
    getAttachmentsPlainText(link){
        let attachment = "";
        if(link.files.length > 2){
            attachment = (
                link.attachments
            )
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        file.name
                    );
                })
            )
        }

        return attachment;
    }
    getAttachments(link, length = 2){
        let attachment = "";
        if(link.files.length > length){
            if(length === 0){   
                attachment = (
                    <Button justIcon color="transparent" onClick={() => this.onAttachmentsModal(true, link.files)} title={"Files"}>
                        <MaterialIcon path={mdiFileDocumentMultipleOutline} className="MuiSvgIcon-root" />
                    </Button>
                )
            }else{
                attachment = (
                    <Button simple onClick={() => this.onAttachmentsModal(true, link.files)}>{link.attachments}</Button>
                )
            }
        }else {
            attachment = (
                link.files.map((file, key) => {
                    return (
                        <Link href={file.downloadLink} target="_blank" key={key}>
                            {file.name}
                        </Link>
                    );
                })
            )
        }

        return attachment;
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        return moment(expire_at).format("MM/DD/YYYY h:mm A");
    }
    onExpandTableRow(){
        console.log("test");
    }
    getFormatedDownloadDate(date){
        if(!date || date.length <= 0){
            return "";
        }
        return moment(date).format("MM/DD/YYYY h:mm A");
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getTableData(){
        const { response, checked, viewPasswords } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        const { classes } = this.props;
        response.map(link => {
            let fileArray = {
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                type: this.getType(link),
                account: link.account,
                server: "Sendlinx",
                id: link.id,
                sender_email_orignal: link.sender_email,
                sender_email: (
                    <div title={ link.sender_email }>
                        { link.sender_email }
                    </div>
                ),
                recipients: (
                    link.title !== '' && link.title ?
                        link.title
                    :
                        link.recipients.join(",")
                ),
                message: (
                    <div dangerouslySetInnerHTML={{__html: link.message}}></div>
                ),
                subject: link.subject,
                created_at: link.created_at,
                attachmentsPlainText: this.getAttachmentsPlainText(link),
                attachments: this.getAttachments(link),
                attachmentsMobile: this.getAttachments(link, 0),
                expire_at: (
                    <div className={(link.status === 'expire' ? 'expire-link' : 'active-link')}>
                        {this.getFormatedExpiry(link.expire_at)}
                        <Button justIcon color="transparent" onClick={() => this.onChangeExpiryDate(true, link.id, link.expire_at)}>
                            <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                        </Button>
                    </div>
                ),
                last_downloaded: this.getFormatedDownloadDate(link.download_date),
                status: (
                    link.state === 'uploaded' || link.state === 'processing' ?
                        <p className='orange'>
                            Processing ...
                        </p>
                    :
                        <p>
                            Ready
                        </p>
                ),
                webLink: (
                    <Button justIcon color="transparent" onClick={() => this.copyDownloadLink(link.webLink, link.state)} title={"Copy Link"}>
                        <MaterialIcon path={mdiContentCopy} className="MuiSvgIcon-root" />
                    </Button>
                ),
                action: (
                    <Button justIcon color="transparent" onClick={() => this.onChangeLinkModal(true, link.id, link.state)} title={"Change Link"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='change-link' width="28" height="28" fill="none" viewBox="0 0 16 16">
                            <path d="M9.49999 3H10.5C12.433 3 14 4.567 14 6.5 14 6.80964 13.9598 7.10989 13.8843 7.39581 13.5943 7.20702 13.2732 7.08576 12.9433 7.03204 12.9804 6.86059 13 6.68258 13 6.5 13 5.17452 11.9685 4.08996 10.6644 4.00532L10.5 4H9.49999C9.22386 4 8.99999 3.77614 8.99999 3.5 8.99999 3.25454 9.17687 3.05039 9.41012 3.00806L9.49999 3zM5.5 3H6.5C6.77614 3 7 3.22386 7 3.5 7 3.74546 6.82312 3.94961 6.58988 3.99194L6.5 4H5.5C4.11929 4 3 5.11929 3 6.5 3 7.82548 4.03154 8.91004 5.33562 8.99468L5.5 9H6.5C6.77614 9 7 9.22386 7 9.5 7 9.74546 6.82312 9.94961 6.58988 9.99194L6.5 10H5.5C3.567 10 2 8.433 2 6.5 2 4.63144 3.46428 3.10487 5.30796 3.00518L5.5 3z"/>
                            <path d="M5.50023 6L10.5002 6.0023C10.7764 6.00242 11.0001 6.22638 11 6.50252 10.9999 6.74798 10.8229 6.95205 10.5897 6.99428L10.4998 7.0023 5.49977 7C5.22363 6.99987 4.99987 6.77591 5 6.49977 5.00011 6.25431 5.17708 6.05024 5.41035 6.00801L5.50023 6zM11.5183 8.42604C12.0863 7.85799 13.0073 7.85799 13.5754 8.42604 14.1434 8.99409 14.1434 9.91508 13.5754 10.4831L9.81912 14.2394C9.60012 14.4584 9.32571 14.6137 9.02524 14.6889L7.8602 14.9801C7.35355 15.1068 6.89462 14.6478 7.02128 14.1412L7.31254 12.9762C7.38766 12.6757 7.54303 12.4013 7.76203 12.1823L11.5183 8.42604zM12.2254 9.13314L8.46914 12.8894C8.37829 12.9802 8.31385 13.0941 8.28269 13.2187L8.11601 13.8854 8.78271 13.7187C8.90734 13.6875 9.02117 13.6231 9.11202 13.5323L12.8683 9.77602C13.0458 9.5985 13.0458 9.31067 12.8683 9.13314 12.6907 8.95562 12.4029 8.95562 12.2254 9.13314z"/>
                        </svg>
                    </Button>
                ),
                delete: (
                    <Button justIcon color="transparent" onClick={() => this.onDeleteLinkModal(true, link.id)} title={"Delete"}>
                        <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                    </Button>
                ),
                orignalPath: link.path,
                path: (
                    <div title={ link.path }>
                        { link.path }
                    </div>
                ),
                title: link.title,
                link_password: (
                    <>
                        {
                            link.link_password ?
                                <>
                                    <div className="sd-password-field">
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                link.link_password
                                            :
                                                <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.link_password)}}></div>
                                        }
                                    </div>
                                    <Button justIcon color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                <VisibilityOffIcon />
                                            :
                                                <VisibilityIcon />
                                        }
                                    </Button>
                                </>
                            :
                                <></>
                        }
                        <Button justIcon color="transparent" onClick={() => this.onChangeLinkPassword(true, link.id, link.link_password)} title={"Link Password"}>
                            <MaterialIcon path={mdiPencil} className="MuiSvgIcon-root" />
                        </Button>
                    </>
                ),
                three_dot: (
                    <Button justIcon color="transparent" onClick={() => this.onLinkDetailModal(true, link)} title={""}>
                        <MaterialIcon path={mdiDotsVertical} className="MuiSvgIcon-root" />
                    </Button>
                )
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    handleToggle(link) {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(link.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(link.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked,
        });
    }
    onToggleAllLinks(e){
        const checkedLinks = [];
        if(e.target.checked){
            const { response } = this.state;

            response.map((file, key) => {
                checkedLinks.push(file.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedLinks
        });
    }
    onChangeLinkModal(status = false, transferId = null, state = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        if(state === 'uploaded' || state === 'processing'){
            this.setState({
                linkProcessingModal: true
            });
            
            return;
        }
        
        this.setState({
            changeLinkModal: status,
            transferId: transferId
        });
    }
    onChangeLinkModalSuccess(){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingLink: true,
            cancelToken: source,
        });

        Api.changeLink(transferId, {files: links}, source).then(data => {
            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeExpiryDate(status = false, transferId = null, expire = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            changeExpiryDateModal: status,
            transferId: transferId,
            expire: expire
        });
    }
    onChangeExpiryDateSuccess(expire){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingExpiryDate: true,
            cancelToken: source,
        });

        let orignalExpire = expire;
        const parsedDate = new Date(expire);

        if(expire !== '' && parsedDate !== "Invalid Date"){
           const day = parsedDate.getDate();
            const month = parsedDate.getMonth() + 1;
            const year = parsedDate.getFullYear();
            const hours = parsedDate.getHours();
            const mins = parsedDate.getMinutes();
            const secs = parsedDate.getSeconds();
            expire = day+"-"+month+"-"+year+" "+hours+":"+mins+":"+secs;
        }

        const requestData = {
            expire: expire,
            files: links
        };

        Api.changeExpiryDate(transferId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        expire_at:  orignalExpire
                    }
                })
            }

            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    onChangeLinkPassword(status = false, transferId = null, linkPassword = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            changeLinkPasswordModal: status,
            transferId: transferId,
            linkPassword: linkPassword
        });
    }
    onChangeLinkPasswordSuccess(linkPassword){
        let { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            changingLinkPassword: true,
            cancelToken: source,
        });

        const requestData = {
            linkPassword: linkPassword,
            files: links
        };

        Api.changeLinkPassword(transferId, requestData, source).then(data => {
            if(this.state.linkDetail !== null){
                this.setState({
                    linkDetail: {
                        ...this.state.linkDetail,
                        link_password:  linkPassword
                    }
                })
            }

            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    copyDownloadLink(link, state){
        if(state === 'uploaded' || state === 'processing'){
            this.setState({
                linkProcessingModal: true
            });
            
            return;
        }
        helper.copyTextToClipboard(link);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    onDeleteLinkModal(status = false, transferId = null){
        const { checked } = this.state;
        if(checked.length <= 0 && transferId === null && status === true){
            return;
        }
        this.setState({
            deleteLinkModal: status,
            transferId: transferId
        });
    }
    onDeleteLinkModalSuccess(){
        const { transferId, checked } = this.state;
        let links = [transferId];
        if(transferId === null){
            links = checked;
        }
        const source = axios.CancelToken.source();
        this.setState({
            deletingLink: true,
            cancelToken: source,
        });
        const requestData = {
            links: links
        }

        Api.deleteLink(requestData, source).then(data => {
            this.loadLinks();
        }).catch(err => {
            console.log(err);
        });
    }
    getType(link){
        let label = "Direct Upload";
        if(link.upload_type.length <= 0){
            link.upload_type = link.type;
        }
        switch(link.upload_type){
            case 'web':
                label = 'Web';
                break;
            case 'my_docs':
                label = 'My Docs';
                break;
            case 'direct_upload':
                label = 'Direct Upload';
                break;
            case 'outlook':
                label = 'Outlook';
                break;
            case 'desktop':
                label = 'Desktop';
                break;
            case 'mobile':
                label = 'Mobile';
                break;
            // case 'share':
            //     label = 'Shared';
            //     break;
            // case 'manager_upload':
            //     label = 'File Manager Upload';
            //     break;
            default:
                if(link.path.indexOf("Web")){
                    label = "Web";
                }else if(link.path.indexOf("Outlook")){
                    label = "Outlook";
                }else if(link.path.indexOf("My Docs")){
                    label = "Desktop";
                }
                break;
        }
        return label;
    }
    onChangeView(type){ 
        this.setState({
            viewType: type
        })
    }
    onLinkProcessingModalSuccess(){
        this.setState({
            linkProcessingModal: false,
        });
    }
    onLinkDetailModal(status = false, linkDetail = null){
        const { checked } = this.state;
        if(checked.length <= 0 && linkDetail === null && status === true){
            return;
        }
        this.setState({
            linkDetailModal: status,
            linkDetail: linkDetail,
        });
    }
    onTabChange(activeTab){
        this.setState({
            activeTab: parseInt(activeTab)
        });
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages, changeLinkModal, changingLink, 
            changeExpiryDateModal, changingExpiryDate, expire, deleteLinkModal, deletingLink, filter, checked,
             attachmentsModal, attachmentFiles, viewType, searchFormData, changeLinkPasswordModal, changingLinkPassword, linkPassword,
            checkedAll, linkProcessingModal, linkDetailModal, linkDetail, activeTab } = this.state;
        const { authorized } = this.store.getState();

        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content+" content-section"}>
                        <div className={classes.controls}>
                            <GridItem>
                                <GridContainer className={classes.alignItems}>
                                    <GridItem xs={12} sm={6} md={8}>
                                        <NavPills
                                            color="custom"
                                            active={activeTab}
                                            onChange={(activeTab) => this.onTabChange(activeTab)}
                                            tabs={[
                                                {
                                                    tabButton: "Internal Links",
                                                    tabTitle: "Source in Sendlinx",
                                                    tabContent: null
                                                },
                                                {
                                                    tabButton: "External Links",
                                                    tabTitle: "Source on External sites",
                                                    tabContent: null
                                                },
                                            ]}
                                        />
                                    </GridItem>
                                    {
                                        activeTab === 0 ?
                                            <GridItem xs={12} sm={6} md={4}>                                
                                                <SearchInput
                                                    searchFormData={searchFormData}
                                                    onSuccess={(searchData) => this.onSearchInputSuccess(searchData)}
                                                    clearSearch={() => this.clearSearch()}
                                                    history={this.history}
                                                    store={this.store}
                                                    inputProps={{
                                                        placeholder: "Type File Name(s) or Destination"
                                                    }}
                                                />
                                            </GridItem>
                                        :
                                        null
                                    }
                                </GridContainer>
                            </GridItem>
                            {
                                activeTab === 0 ?
                                    <GridItem className={classes.filterGridItem+" "+classes.filterGridItemSm}>
                                        <div className={classes.filterGridItem}>
                                            <ul className={classes.viewType+' '+classes.linkFilters}>
                                                <li className={viewType === "summary" ? 'active' : ''} onClick={() => this.onChangeView('summary')}>
                                                    <span>Summary View</span>
                                                </li>
                                                <li className={viewType === "mobile" ? 'active' : ''} onClick={() => this.onChangeView('mobile')}>
                                                    <span>Mobile View</span>
                                                </li>
                                                <li className={viewType === "full" ? 'active' : ''} onClick={() => this.onChangeView('full')}>
                                                    <span>Full View</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className={classes.linkFilters}>
                                            <li className={filter === "" ? "active" : ""} onClick={() => this.loadLinks(false, "")}>
                                                <img src={allLinksIcon} alt="All Links"/>
                                                <span>All Links</span>
                                            </li>
                                            <li className={filter === "mydocs" ? "active" : ""} onClick={() => this.loadLinks(false, "mydocs")}>
                                                <img src={uploadLinksIcon} alt="My Docs"/>
                                                <span>My Docs</span>
                                            </li>
                                            <li className={filter === "outlook" ? "active" : ""} onClick={() => this.loadLinks(false, "outlook")}>
                                                <img src={outlookLinksIcon} alt="Outlook"/>
                                                <span>Outlook</span>
                                            </li>
                                            {/* <li className={filter === "desktop" ? "active" : ""} onClick={() => this.loadLinks(false, "desktop")}>
                                                <img src={desktopLinksIcon} alt="Desktop"/>
                                                <span>Desktop</span>
                                            </li> */}
                                            <li className={filter === "web" ? "active" : ""} onClick={() => this.loadLinks(false, "web")}>
                                                <img src={webLinksIcon} alt="Web"/>
                                                <span>Web</span>
                                            </li>
                                        </ul>
                                    </GridItem>
                                :
                                null
                            }
                        </div>
                        {
                            activeTab === 0 ?
                                <>
                                    <div className={classes.files}>
                                        <div className={classes.filesContainer}>
                                            {
                                                loading ?
                                                    <LoaderComponent color="custom" align="center" />
                                                :
                                                null
                                            }
                                            <div className={(loading ? classes.loading : "")}>
                                                {
                                                    viewType === 'summary' ?
                                                        <SummaryView 
                                                            tableData={tableData} 
                                                            limit={limit} 
                                                            checkedAll={checkedAll} 
                                                            onCheckedAll={(e) => this.onToggleAllLinks(e)} 
                                                        />
                                                    : viewType === 'full' ?
                                                        <FullView 
                                                            tableData={tableData} 
                                                            limit={limit} 
                                                            checkedAll={checkedAll} 
                                                            onCheckedAll={(e) => this.onToggleAllLinks(e)} 
                                                        />
                                                    : viewType === 'mobile' ?          
                                                        <MobileView
                                                            tableData={tableData} 
                                                            limit={limit} 
                                                            checkedAll={checkedAll} 
                                                            onCheckedAll={(e) => this.onToggleAllLinks(e)} 
                                                        />
                                                    :
                                                        <></>
                                                }
                                            </div>
                                        </div>
                                        {
                                            loading ?
                                                <></>
                                            :
                                                <ul className={classes.linkFilters+" "+classes.fileActions}>
                                                    <li className={(checked.length >= 2 ? classes.fileActionEnabled : "")}>
                                                        <Button color="transparent" onClick={() => this.onChangeLinkModal(true)}>
                                                            <MaterialIcon path={mdiLinkVariant} className="MuiSvgIcon-root" />
                                                            <span>Change Link</span>
                                                        </Button>
                                                    </li>
                                                    <li className={(checked.length >= 2 ? classes.fileActionEnabled : "")}>
                                                        <Button color="transparent" onClick={() => this.onChangeExpiryDate(true)}>
                                                            <MaterialIcon path={mdiClockCheckOutline} className="MuiSvgIcon-root" />
                                                            <span>Change Expiry</span>
                                                        </Button>
                                                    </li>
                                                    <li className={(checked.length >= 2 ? classes.fileActionEnabled : "")}>
                                                        <Button color="transparent" onClick={() => this.onDeleteLinkModal(true)}>
                                                            <MaterialIcon path={mdiCloseCircleOutline} className="MuiSvgIcon-root" />
                                                            <span>Delete</span>
                                                        </Button>
                                                    </li>
                                                </ul>
                                        }
                                    </div>
                                    <div className={classes.loadMore}>
                                        {
                                            tableData.length > 0 && loadingMore === false && page < totalPages ?
                                                <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadLinks(true)}>
                                                    <RefreshIcon className={classes.icons} />View More
                                                </Button>
                                                
                                            :
                                                loadingMore === true ?
                                                    <LoaderComponent color="custom" align="center" />
                                                :
                                                <></>
                                        }
                                    </div>
                                </>
                            :
                            <ExternalLinks sidebar={false} store={this.store} history={this.history} />
                        }
                    </div>
                    {
                        changeLinkModal ?
                            <ConfirmationModal 
                                open={changeLinkModal} 
                                saving={changingLink} 
                                onClose={() => this.onChangeLinkModal(false)} 
                                onSuccess={() => this.onChangeLinkModalSuccess()}
                                confirmationMessage="Are you sure you want to change link?"
                            />
                        :
                        <></>
                    }
                    {
                        deleteLinkModal ?
                            <DeleteConfirmationModal 
                                deleteNote={true} 
                                open={deleteLinkModal} 
                                saving={deletingLink} 
                                onClose={() => this.onDeleteLinkModal(false)} 
                                onSuccess={() => this.onDeleteLinkModalSuccess()}
                                confirmationMessage="Are you sure you want to delete link?"
                                checked={checked}
                            />
                        :
                        <></>
                    }
                    {
                        attachmentsModal ?
                            <AttachmentsModal 
                                open={attachmentsModal} 
                                onClose={() => this.onAttachmentsModal(false)}
                                attachmentFiles={attachmentFiles}
                            />
                        :
                            <></>
                    }
                    {
                        linkDetailModal ?
                            <LinkDetailModal 
                                open={linkDetailModal} 
                                onClose={() => this.onLinkDetailModal(false)}
                                linkDetail={linkDetail}
                                onChangeLinkPassword={(status, transferId, linkPassword) => this.onChangeLinkPassword(status, transferId, linkPassword)}
                                onChangeExpiryDate={(status, transferId, expire) => this.onChangeExpiryDate(status, transferId, expire)}
                            />
                        :
                            <></>
                    }
                    {
                        changeExpiryDateModal ?
                            <ChangeExpiryDateModal 
                                open={changeExpiryDateModal} 
                                saving={changingExpiryDate} 
                                onClose={() => this.onChangeExpiryDate(false)} 
                                onSuccess={(expire) => this.onChangeExpiryDateSuccess(expire)}
                                confirmationMessage="Are you sure you want to change the expiry?"
                                expire={expire}
                            />
                        :
                        <></>
                    }
                    {
                        changeLinkPasswordModal ?
                            <ChangeLinkPasswordModal 
                                open={changeLinkPasswordModal} 
                                saving={changingLinkPassword} 
                                onClose={() => this.onChangeLinkPassword(false)} 
                                onSuccess={(linkPassword) => this.onChangeLinkPasswordSuccess(linkPassword)}
                                confirmationMessage="Are you sure you want to change the password?"
                                linkPassword={linkPassword}
                            />
                        :
                        <></>
                    }
                    {
                        linkProcessingModal ?
                            <ProcessingModal 
                                open={linkProcessingModal} 
                                onSuccess={() => this.onLinkProcessingModalSuccess()}
                                successMessage='Your link is still processing. Please check back in a few moments.'
                                store={this.store}
                            />
                        :
                        <></>
                    }
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserLinks.propTypes = {
    classes: PropTypes.object
};

export default withStyles(linksPageStyle)(UserLinks);
