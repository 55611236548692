import axios from "axios";
import Api from "../../../assets/js/utils/Api.js";
import React from 'react'
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button.jsx";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";

import imageModalStyle from "../../../assets/jss/user/imageModalStyle.jsx";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

const imageModal = class extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            file: this.props.file
        };
    }
    componentDidMount(){
        this.addActivity();
    }
    addActivity(){
        const { file } = this.state;
        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.addActivity(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
    }
    render() {
        const { file } = this.state
        const { classes } = this.props;

        return (
            <Dialog
                classes={{
                    root: classes.modalRoot,
                    paper: classes.modal
                }}
                open={this.props.open}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="newFileModal-modal-slide-title"
                aria-describedby="newFileModal-modal-slide-description"
            >
                <DialogTitle
                    id="newFileModal-modal-slide-title"
                    disableTypography
                    className={classes.modalHeader}
                    >
                        <Button
                            simple
                            className={classes.modalCloseButton+" "+classes.modalCloseButtonCustom}
                            key="close"
                            aria-label="Close"
                            onClick={() => this.props.onClose()}
                        >
                            {" "}
                            <Close className={classes.modalClose} />
                        </Button>
                </DialogTitle>
                <DialogContent
                    id="newFileModal-modal-slide-description"
                    className={classes.modalBody}
                >
                    <img className={classes.imagePreview} src={file.thumbnails.large} alt="thumbnail" />
                </DialogContent>
                <DialogActions className={classes.modalFooter+' '+classes.justifyContentCenter}>
                    <Button color="custom" onClick={() => this.props.onDownload()}>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

imageModal.defaultProps = {
    open: false,
}
imageModal.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool
}
export default withStyles(imageModalStyle)(imageModal);