import React from 'react';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import { Link as RouterLink } from "react-router-dom";
import axios from "axios";
import Api from "../../../assets/js/utils/Api";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from "../../../assets/js/utils/Element";
import PropTypes from "prop-types";
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from "react-table";
import LoaderComponent from "../../components/Loader";
import Link from "@material-ui/core/Link";
import RefreshIcon from "@material-ui/icons/Refresh";

import MaterialIcon from "@mdi/react";
import { mdiStar, mdiStarOutline } from '@mdi/js';
import foldericon from "../../../assets/img/foldericon.png";
// import ChevronRight from "@material-ui/icons/ChevronRight";

import withStyles from "@material-ui/core/styles/withStyles";
import userFavouritesPageStyle from "../../../assets/jss/user/userFavouritesPageStyle.jsx";
const history = createBrowserHistory();

const UserFavourites = class extends React.Component {
    constructor(props){
        super(props);
        this.store = this.props.store;
        this.history = this.props.history;
        let path = helper.getParam("dir");
        if(path === null || path === "null"){
            path = "/";
        }
        const backPath = this.getBackPath(path);

        this.state = {
            limit: 200,
            totalItemCount: 0,
            response: null,
            cancelToken: null,
            page: 1,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            checked: [],
            path: decodeURIComponent(path.trim()),
            backPath: backPath,
            deleteFileModal: false,
            uploadFileModal: false,
            favourites: [],
            shareFileModal: false,
            sharingFiles: false,
            downloadModal: false,
            downloadingFiles: false,
            downloadUrl: ""
        };

        this.loadingFolder = false;
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
            return;
        }
        if(authorized){
            this.loadFiles();
        }
    }
    componentWillUnmount(){
        if (this.state.cancelToken) {
            this.state.cancelToken.cancel('Request Cancelled')
        }
    }
    getBackPath(path){
        if(typeof(path) !== "string" || path.trim().length <= 0){
            return "";
        }
        if(path.length <= 1 || path.trim() === "/"){
            return "";
        }
        if(path.substr(0, 1) !== "/"){
            path += "/";
        }
        path = decodeURIComponent(path.trim()); //Decode path
        path = path.replace(/^\/+|\/+$/g, ''); //Remove trailing slash
        const pathArray = path.split("/");
        pathArray.pop();
        let backPath = pathArray.join("/");
        if(backPath.length <= 0){
            backPath = "/";
        }
        return backPath;
    }
    loadFiles(viewMore = false, newPath = null, backPath = ""){
        const source = axios.CancelToken.source();
        let { page, path, favourites } = this.state;
        if(viewMore){
            page += 1;
        }else{
            page = 1;
        }
        let requestData = {
            page: page,
            limit: 200,
            favourites: 1
        };
        if(path === "/" && newPath !== null){
            path = "";
        }
        requestData['path'] = path;
        if(newPath !== null){
            path += "/"+newPath;
            requestData['path'] = path;
        }
        if(backPath !== ""){
            requestData['path'] = path = backPath;
        }
        backPath = this.getBackPath(path);
        history.push("/user/favourites?dir="+encodeURIComponent(path));
        Api.getFavouriteFiles(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            data.response.map(file => {
                if(file.favourite){
                    favourites.push(file.id);
                }
                return null;
            });
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages,
                favourites: favourites
            });
            this.loadingFolder = false;
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            showError: false, 
            cancelToken: source,
            page: page,
            path: path,
            backPath: backPath,
            checked: viewMore ? this.state.checked : [],
            uploadFileModal: false
        });
        this.loadingFolder = true;
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    getColumns(){
        let columns = [
            {
                Header: "Name",
                accessor: "orignalName",
                headerClassName: "hd_name hd_show_head",
                className: "hd_name td_name td_hide",
                resizable: false
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_name hd_hide",
                className: "hd_name td_name",
            },
            {
                Header: "Size",
                accessor: "orignalSize",
                headerClassName: "hd_size hd_show_head",
                className: "hd_size td_size td_hide",
                resizable: false
            },
            {
                Header: "Size",
                accessor: "size",
                headerClassName: "hd_size hd_hide",
                className: "hd_size td_size",
            },
            {
                Header: "Uploaded",
                accessor: "date",
                headerClassName: "hd_creation_date",
                className: "hd_creation_date td_creation_date",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Creator",
                accessor: "owner",
                headerClassName: "hd_owner",
                className: "hd_owner td_owner",
            },
            
        ];

        return columns;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, backPath } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        if(backPath.length > 0){
            let fileArray = {
                id: 0,
                check: "",
                orignalName: "",
                name: (
                    <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, backPath);}}>
                        <Button color="transparent" justIcon className={classes.fileIcon}>
                            <img src={foldericon} alt={"folder"} />
                        </Button>
                        {"..."}
                    </Link>
                ),
                orignalSize: "",
                size: "",
                date: "",
                owner: "",
            };
            tableData.push(fileArray);
        }
        response.map(file => {
            let fileArray = {
                id: file.id,
                default: file.default,
                orignalName: file.name,
                name: this.getFileName(file),
                orignalSize: file.orignalSize,
                size: file.size,
                date: file.date,
                owner: file.owner,
            };
            tableData.push(fileArray);
            return null;
        });
        return tableData;
    }
    getFileHref(file){
        let href = "/user/home";
        href += "?dir="+encodeURIComponent(file.path);
        return href;
    }
    getFileName(file){
        const { classes } = this.props;
        const { favourites } = this.state;
        return (
            <div>
                <Button color="transparent" justIcon className={classes.fileIcon} onClick={() => this.favourite(file)}>
                    {
                        favourites.includes(file.id) ?
                            <MaterialIcon path={mdiStar} className="MuiSvgIcon-root star_filled" />
                        :
                        <MaterialIcon path={mdiStarOutline} className="MuiSvgIcon-root star_outlined" />
                    }
                </Button>
                {
                    file.type === "folder" ?
                        <RouterLink to={this.getFileHref(file)}>
                            <Button color="transparent" justIcon className={classes.fileIcon}>
                                <img src={foldericon} alt={"folder"} />
                            </Button>
                            {file.name}
                        </RouterLink>
                    :
                    <RouterLink to={this.getFileHref(file)}>
                        { 
                            helper.getFileIcon(file, classes)
                        }
                        {file.name}
                    </RouterLink>
                }
            </div>
        )
    }
    loadFolder(e, parent){
        e.preventDefault();
        this.loadFiles(false, parent.name);
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    favourite(file){
        const { favourites } = this.state;
        const currentIndex = favourites.indexOf(file.id);
        const newFavourites = [...favourites];
    
        if (currentIndex === -1) {
            newFavourites.push(file.id);
        } else {
            newFavourites.splice(currentIndex, 1);
        }

        const source = axios.CancelToken.source();
        const requestData = {
            file_id: file.id
        }
        Api.favouriteFile(requestData, source).then(data => {
            
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            favourites: newFavourites
        });
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getBreadCrumbs(){
        let { path } = this.state;
        let breadcrumbs = [{
            value: "/",
            label: "Home"
        }];
        if(path === "/"){
            return breadcrumbs;
        }
        if(path.substr(0,1) === "/"){
            path = path.substr(1);
        }
        const pathArray = path.split("/");
        let newPath = "";
        pathArray.map(path => {
            newPath += "/"+path;
            breadcrumbs.push({
                value: newPath,
                label: path
            });
            return null;
        });
        return breadcrumbs;
    }
    render() {
        const { classes } = this.props;
        const { limit, loading, loadingMore, page, totalPages } = this.state;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }
        // const breadcrumbs = this.getBreadCrumbs();
        const tableData = this.getTableData();
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    <div className={classes.content}>
                        <div className={classes.controls}>
                            <GridItem>
                                <h4>Quick Access</h4>
                            </GridItem>
                        </div>
                        {/* <div className={classes.breadcrumbs}>
                            <img src={foldericon} alt={"folder"} />
                            {
                                breadcrumbs.map((breadCrumb, key) => {
                                    return (
                                        <div key={key}>
                                            {
                                                key !== 0 ?
                                                    <span><ChevronRight /></span>
                                                :
                                                <></>
                                            }
                                            <Link href="/user/home" onClick={(e) => {e.preventDefault(); this.loadFiles(false, null, breadCrumb.value);}}>
                                                {breadCrumb.label}
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                        <div className={classes.files}>
                            {
                                loading ?
                                    <LoaderComponent color="custom" align="center" />
                                :
                                    <ReactTable
                                        columns={this.getColumns()}
                                        data={tableData}
                                        pageSizeOptions={[500]}
                                        defaultPageSize={limit}
                                        showPaginationTop={false}
                                        minRows={0}
                                        showPaginationBottom={false}
                                        className={"-striped -highlight "+classes.filesTable}
                                        getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                        getNoDataProps={() => this.getNoDataProps()}
                                    />
                            }
                            
                        </div>
                        <div className={classes.loadMore}>
                            {
                                tableData.length > 0 && loadingMore === false && page < totalPages ?
                                    <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadFiles(true)}>
                                        <RefreshIcon className={classes.icons} />View More
                                    </Button>
                                    
                                :
                                    loadingMore === true ?
                                        <LoaderComponent color="custom" align="center" />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
}

UserFavourites.propTypes = {
    classes: PropTypes.object
};

export default withStyles(userFavouritesPageStyle)(UserFavourites);
