const searchInputStyle = theme => ({
    searchInput: {
        "& .search-input": {
            paddingTop: "0px",
            margin: "0px"
        },
        "& .advanceSearchIcon, & .clear-icon": {
            cursor: "pointer"
        },
        "& input": {
            color: theme.customize.color
        },
        "& svg": {
            fill: theme.customize.iconColor+' !important',
            marginRight: "0px !important",
            display: "block",
        },
        "& button": {
            "&:hover": {
                backgroundColor: "transparent"
            }
        },
        "& .MuiInputLabel-root": {
            display: "none"
        },
        "& .MuiInput-root": {
            background: theme.customize.searchBgColor,
            borderRadius: "10px",
            padding: "5px 18px",
            border: "3px solid transparent",
            "&:before, &:after": {
                border: "none !important"
            },
            "&.input-filled, &.Mui-focused": {
                borderColor: theme.customize.searchBorderColor,
                background: theme.customize.searchActiveBgColor,
            }
        },
        "& .search-icon": {
            marginRight: "20px"
        },
        "& .MuiInputAdornment-positionEnd": {
            columnGap: "20px",
            "& svg": {
                width: "auto"
            }
        }
    },
    searchIcon: {
        marginRight: "0px !important",
        paddingRight: "0px",
        padding: "0px",
        "& svg": {
            // width: "30px !important",
            // height: "30px"
        }
    }
});

export default searchInputStyle;
