import React from 'react';
import axios from "axios";
import moment from 'moment';
import Api from "../../../assets/js/utils/Api";
import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
import Auth from "../../../assets/js/utils/Auth";
import { helper } from '../../../assets/js/utils/Element';
import BackgroundSlider from "../../components/Slider/BackgroundSlider";
import Button from "../../components/CustomButtons/Button";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import LoaderComponent from "../../components/Loader";
import ExternalLinkModal from "../../components/User/ExternalLinkModal";
import ReactTable from "react-table";
import RefreshIcon from "@material-ui/icons/Refresh";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteExternalLinkModal from "../../components/User/DeleteExternalLinkModal";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from "@material-ui/core/styles/withStyles";
import userHomePageStyle from "../../../assets/jss/user/userHomePageStyle.jsx";

import newlinkicon from "../../../assets/img/New_Link.png";
import newlinkiconLight from "../../../assets/img/New_Link-light.png";

import MaterialIcon from "@mdi/react";
import { mdiTrashCanOutline, mdiContentCopy, mdiDeleteOutline, mdiPlus } from '@mdi/js';

const ExternalLinks = class extends React.Component {
    constructor(props){
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;

        this.state = {
            totalItemCount: 0,
            response: null,
            loading: false,
            loadingMore: false,
            totalPages: 1,
            page: 1,
            checked: [],
            checkedAll: false,
            linkName: false,
            externalLink: null,
            linkModal: false,
            savingLinkModal: false,
            newLinkUploaded: false,
            errorMessage: null,
            deleteModal: false,
            snackbarOpen: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            linkId: null,
            viewPasswords: []
        };

        this.closeSnackbar = this.closeSnackbar.bind(this);
    }
    componentDidMount(){
        const { authorized } = this.store.getState();
        if(!authorized && !Auth.hasAccessToken()){
            const location = this.history.location;
            const loginRequired = "/auth/login?return="+encodeURIComponent(location.pathname+location.search);
            this.history.push(loginRequired);
        }
        this.loadExternalLinks();
    }
    getDefaultLinkDetail(){
        return {
            id: "",
            hash: "",
            url: "",
            title: "",
            linkPassword: "",
            expire: null,
            hideLink: false,
        }
    }
    loadExternalLinks(viewMore = false){
        const source = axios.CancelToken.source();
        let page = this.state.page;
        if(viewMore){
            page += 1;
        }
        const requestData = {
            page: page,
            limit: 50,
        };
        Api.getExternalLinks(requestData, source).then(data => {
            const oldResponse = (viewMore ? this.state.response:[]);
            const response = oldResponse.concat(data.response);
            this.setState({
                totalItemCount: data.totalItemCount,
                response: response,
                loading: false,
                loadingMore: false,
                totalPages: data.totalPages
            });
        }).catch(err => {
            console.log(err);
        });
        this.setState({
            loading: (!viewMore), 
            loadingMore: viewMore,
            cancelToken: source,
            page: page,
        });
    }
    onLinkModal(status = false){
        let state = {
            linkName: status,
            linkModal: status,
            linkDetail: this.getDefaultLinkDetail(),
            editMode: false,
            errorMessage: null
        }

        if(status === false){
            state['checked'] = [];
            state['checkedAll'] = false;
        }

        this.setState(state);
    }
    onExternalLinkModalSuccess(data, editMode = false){
        const source = axios.CancelToken.source();

        this.setState({
            savingLinkModal: true,
            cancelToken: source,
            errorMessage: null
        })

        if(editMode === true) {
            Api.editExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }else{
            Api.createExternalLink(data, source).then(data => {
                let externalLink = "/external-links/"+data.hash;
                this.setState({
                    savingLinkModal: false, 
                    externalLink: externalLink,
                    linkName: false,
                    editMode: false,
                    cancelToken: null,
                    linkDetail: this.getDefaultLinkDetail(),
                    checked: [],
                    checkedAll: false
                });
            }).catch(err => {
                if(typeof(err) === "object" && err.hasOwnProperty("message")){
                    this.setState({
                        savingLinkModal: false, 
                        cancelToken: null,
                        errorMessage: err.message
                    });
                }
            });
        }
    }
    onLinkModalSuccess(){
        this.setState({
            cancelToken: null,
            linkModal: false,
            savingLinkModal: false,
            linkName: false,
            errorMessage: null,
            newLinkUploaded: false,
            editMode: false,
            linkDetail: this.getDefaultLinkDetail(),
            checked: [],
            checkedAll: false,
            externalLink: null
        }, () => {
            this.loadExternalLinks();
        });
    }
    onToggleAllFiles(e){
        const checkedLinks = [];
        if(e.target.checked){
            const { response } = this.state;
            response.map((link, key) => {
                checkedLinks.push(link.id);
                return null;
            });
        }
        
        this.setState({
            checkedAll: !this.state.checkedAll,
            checked: checkedLinks
        });
    }
    getColumns(){
        const { classes } = this.props;
        const header = (
            <Checkbox
                tabIndex={-1}
                checked={this.state.checkedAll}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                onChange={(e) => this.onToggleAllFiles(e)}
                classes={{
                    checked: classes.checked,
                    root: classes.checkRoot
                }}
            />
        );
        let columns = [
            {
                Header: header,
                accessor: "check",
                sortable: false,
                filterable: false,
                headerClassName: "hd_check",
                className: "hd_check td_check",
                resizable: false,
            },
            {
                Header: "Name",
                accessor: "name",
                headerClassName: "hd_title",
                className: "hd_title td_title",
            },
            {
                Header: "Source Path",
                accessor: "url",
                headerClassName: "hd_url",
                className: "hd_url td_url",
            },
            {
                Header: "Password",
                accessor: "password",
                headerClassName: "hd_password",
                className: "hd_password td_password",
            },
            {
                Header: "Expiry Date",
                accessor: "expire_at_orignal",
                headerClassName: "hd_expire hd_show_head",
                className: "hd_expire td_expire td_hide",
            },
            {
                Header: "Expiry Date",
                accessor: "expire_at",
                headerClassName: "hd_expire hd_hide",
                className: "hd_expire td_expire",
                sortMethod: (a, b) => {
                    var a1 = new Date(a).getTime();
                    var b1 = new Date(b).getTime();
                  if(a1<b1)
                    return 1;
                  else if(a1>b1)
                    return -1;
                  else
                    return 0;
                }
            },
            {
                Header: "Created On",
                accessor: "created_at",
                headerClassName: "hd_expire",
                className: "hd_expire td_expire",
            },
            {
                Header: "Actions",
                accessor: "actions",
                headerClassName: "hd_action",
                className: "hd_action td_action",
            },
        ];

        return columns;
    }
    onViewPassword(link){
        const { viewPasswords } = this.state;
        const currentIndex = viewPasswords.indexOf(link.id);
        const newViewPasswords = [...viewPasswords];
    
        if (currentIndex === -1) {
            newViewPasswords.push(link.id);
        } else {
            newViewPasswords.splice(currentIndex, 1);
        }

        this.setState({
            viewPasswords: newViewPasswords
        });
    }
    convertStringToPass(password){
        let string = "";
        for (let i = 0; i < password.length; i++) {
            string +="<span></span>";
        }

        return string;
    }
    getTableData(){
        const { classes } = this.props;
        const { response, checked, viewPasswords } = this.state;
        if(response === null){
            return [];
        }
        let tableData = [];
        response.map(link => {
            let linkArray = {
                id: link.id,
                check: (
                    <Checkbox
                        tabIndex={-1}
                        checked={checked.includes(link.id)}
                        className={classes.positionAbsolute}
                        onClick={() => this.handleToggle(link)}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                            checked: classes.checked,
                            root: classes.checkRoot
                        }}
                    />
                ),
                name: link.name,
                url: link.url,
                hide_link: (link.hide_link ? "Yes" : "No"),
                password: (
                    <>
                        {
                            link.password ?
                                <>  
                                    <div className="sd-password-field">
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                link.password
                                            :
                                                <div className="sd-passwords" dangerouslySetInnerHTML={{__html: this.convertStringToPass(link.password)}}></div>
                                        }
                                    </div>
                                    <Button justIcon color="transparent" onClick={() => this.onViewPassword(link)} title={"View Password"}>
                                        { 
                                            viewPasswords.includes(link.id) ?
                                                <VisibilityOffIcon />
                                            :
                                                <VisibilityIcon />
                                        }
                                    </Button>
                                </>
                            :
                                <></>
                        }
                    </>
                ),
                expire_at_orignal: link.expire_at,
                expire_at: this.getFormatedExpiry(link.expire_at),
                created_at: link.created_at,
                actions: (
                    <>
                        <Button justIcon color="transparent" onClick={() => this.copyViewLink(link)} title={"Copy Link"}>
                            <MaterialIcon path={mdiContentCopy} className="MuiSvgIcon-root" />
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onEditModal(true, link)} title="Edit">
                            <EditIcon />
                        </Button>
                        <Button justIcon color="transparent" onClick={() => this.onDeleteModal(true, link.id)} title="Delete">
                            <MaterialIcon path={mdiDeleteOutline} className="MuiSvgIcon-root" />
                        </Button>
                    </>
                ),
            };
            tableData.push(linkArray);
            return null;
        });
        return tableData;
    }
    getTrProps(state, rowInfo){
        if (rowInfo && rowInfo.row) {
            // const { checked } = this.state;
            return {
                onClick: (e) => this.handleToggle(rowInfo.original),
                // style: {
                //     background: checked.includes(rowInfo.original.id) ? 'rgba(0, 0, 0, 0.7)' : 'transparent',
                // }
            }
        }else{
            return {}
        }
    }
    getNoDataProps(){
        return { style: { display: 'none' } };
    }
    getFormatedExpiry(expire_at){
        if(!expire_at || expire_at.length <= 0){
            return "No Expiry Date";
        }
        return moment(expire_at).format("MM/DD/YYYY h:mm A");
    }
    onEditModal(status = true, link){
        if(!status){
            this.loadExternalLinks();
            return;
        }
        this.setState({
            linkModal: status,
            linkName: status,
            editMode: status,
            linkDetail: {
                id: link.id,
                hash: link.hash,
                url: link.url,
                title: link.name,
                linkPassword: link.password,
                expire: link.expire_at,
                hideLink: Boolean(Number(link.hide_link)),
            },
        });
    }
    copyViewLink(link){
        helper.copyTextToClipboard(link.viewLink);
        this.setState({snackbarOpen: true});
    }
    closeSnackbar(){
        this.setState({snackbarOpen: false});
    }
    handleToggle(file) {
        if(this.loadingFolder){
            return;
        }
        // if(file.default){
        //     return;
        // }
        const { checked } = this.state;
        const currentIndex = checked.indexOf(file.id);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
            newChecked.push(file.id);
        } else {
            newChecked.splice(currentIndex, 1);
        }
    
        this.setState({
            checked: newChecked
        });
    }
    onDeleteModal(status = true, linkId = null){
        const { checked } = this.state;

        if(checked.length <= 0 && linkId === null && status === true){
            return;
        }
        this.setState({
            deleteModal: status,
            linkId: linkId
        });
    }
    onDeleteModalSuccess(){
        const { checked, response, linkId } = this.state;
        let id = [linkId];
        if(linkId === null){
            id = checked;
        }
        const source = axios.CancelToken.source();
        const requestData = {
            id: id,
        };
        Api.deleteExternalLinks(requestData, source).then(data => {
            //Handle Success;
        }).catch(err => {
            //Handle Error
        });

        let newResponse = [];
        response.map(link => {
            if(!checked.includes(link.id)){
                newResponse.push(link);
            }
            return null;
        });
        this.setState({
            response: newResponse,
            checked: [],
            deleteModal: false,
            deleteAll: false,
            checkedAll: false
        });
    }
    onNewLink(status = false){
        let { checked, response } = this.state;
        if(checked.length <= 0){
            return;
        }
        checked = checked[0];
        let file = response.filter(x => x.id === checked)[0];

        let state = {
            linkName: status,
            linkModal: status,
            linkDetail: {
                ...this.getDefaultLinkDetail(),
                url: file.url
            },
            editMode: false,
        }

        if(status === false){
            state['checked'] = [];
            state['checkedAll'] = false;
        }

        this.setState(state);
    }
    renderContent(){
        const { classes, sidebar } = this.props;
        const { user } = this.store.getState();
        const { linkModal, savingLinkModal, newLinkUploaded, errorMessage, linkName, externalLink, 
            limit, loading, loadingMore, page, totalPages, deleteModal, checked, linkDetail, editMode } = this.state;
        const tableData = this.getTableData();
        return (
            <div className={classes.content}>
                <div className={classes.controls}>
                    {
                        sidebar ?
                            <GridItem>
                                <GridContainer className={classes.alignItems}>
                                    <GridItem xs={12} sm={6} md={8}>
                                        <h4>External Links</h4>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        :
                        null
                    }
                    
                    <div className={classes.controlButtons+" "+(user.theme === 'standard' ? '': 'light-theme-buttons')}>
                        <Button color="transparent" onClick={() => this.onLinkModal(true)} title="Add External Link">
                            <img src={user.theme === 'standard' ? newlinkicon : newlinkiconLight} alt={"link"} /> Add External Link
                        </Button>
                        {
                            checked.length > 0 ?
                                <>
                                    <span className={classes.separator}></span>
                                    <Button color="transparent" onClick={() => this.onNewLink(true)} title="New Link">
                                        <MaterialIcon path={mdiPlus} className="MuiSvgIcon-root plus-icon" /> 
                                        New Link
                                    </Button>
                                    <span className={classes.separator}></span>
                                    <Button color="transparent" onClick={() => this.onDeleteModal()} title="Delete">
                                        <MaterialIcon path={mdiTrashCanOutline} className="MuiSvgIcon-root" />
                                        Delete
                                    </Button>
                                </>
                            :
                                <></>
                        }
                        
                    </div>
                </div>
                <div className={classes.files}>
                    {
                        loading ?
                            <LoaderComponent color="custom" align="center" />
                        :
                            <ReactTable
                                columns={this.getColumns()}
                                data={tableData}
                                pageSizeOptions={[500]}
                                defaultPageSize={limit}
                                showPaginationTop={false}
                                minRows={0}
                                showPaginationBottom={false}
                                className={"-striped -highlight "+classes.filesTable+" "+classes.externalLinksTable}
                                getTrProps={(state, rowInfo) => this.getTrProps(state, rowInfo)}
                                getNoDataProps={() => this.getNoDataProps()}
                            />
                    }
                    
                </div>
                <div className={classes.loadMore}>
                    {
                        tableData.length > 0 && loadingMore === false && page < totalPages ?
                            <Button color="custom" id="contentViewMore" round onClick={(e) => this.loadExternalLinks(true)}>
                                <RefreshIcon className={classes.icons} />View More
                            </Button>
                            
                        :
                            loadingMore === true ?
                                <LoaderComponent color="custom" align="center" />
                            :
                            <></>
                    }
                </div>
                {
                    linkModal ?
                        <ExternalLinkModal
                            open={linkModal}
                            saving={savingLinkModal}
                            onClose={() => this.onLinkModal(false)} 
                            onSuccess={() => this.onLinkModalSuccess()}
                            onLinkName={(data, editMode) => this.onExternalLinkModalSuccess(data, editMode)}
                            loadingMessage="Please wait while we prepare your external link. It may take few minutes depending on size and number of file(s)."
                            successMessage={ newLinkUploaded === true ? 'Your external link is processing. Please check "Status" in the Link Manager in a few moments. Once "Ready", you may copy the link, and share anywhere.' : 'Your link is now ready to be shared.' }
                            errorMessage={errorMessage}
                            externalLink={externalLink}
                            linkName={linkName}
                            editMode={editMode}
                            linkDetail={linkDetail}
                            store={this.store}
                        />
                    :
                    null
                }
                {
                    deleteModal ?
                        <DeleteExternalLinkModal
                            deleteNote={true}
                            confirm={true}
                            deleteLinks={checked}
                            open={deleteModal}
                            onClose={() => this.onDeleteModal(false)}
                            onSuccess={() => this.onDeleteModalSuccess()}
                        />
                    :
                    null
                }
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    className={classes.snackbar}
                    open={this.state.snackbarOpen}
                    autoHideDuration={1000}
                    onClose={this.closeSnackbar}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">Your link has been copied</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.closeSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />
                <Helmet>
                    <title>{process.env.REACT_APP_TITLE}</title>
                </Helmet>
            </div>
        )
    }
    render() {
        const { classes, sidebar } = this.props;
        const { authorized } = this.store.getState();
        if(!authorized){
            return (
                <div className={classes.main}>
                    <BackgroundSlider store={this.store} />
                    <Helmet>
                        <title>{process.env.REACT_APP_TITLE}</title>
                    </Helmet>
                </div>
            )
        }

        if(!sidebar){
            return this.renderContent();
        }
        
        return (
            <div className={classes.main}>
                <div className={classes.container}>
                    {this.renderContent()}
                </div>
            </div>
        )
    }
}

ExternalLinks.defaultProps = {
    sidebar: true
}
ExternalLinks.propTypes = {
    classes: PropTypes.object,
    sidebar: PropTypes.bool
};

export default withStyles(userHomePageStyle)(ExternalLinks);
